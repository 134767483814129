<template>
    <div>
        <div class="boxwrap">
            <div class="title">
                <div class="option-btn mrg-left-10">
					<select class="form-control" v-model="selectBandKey" @change="onChangeSelectBandKey()">
                        <option :value="item.bandKey" v-for="(item, index) in bandList" :key="index">{{ item.name }}</option>
					</select>
				</div>
                <h3 class="text-left">목록 (총
                    <strong class="point">0</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col">글 내용</th>
                        <th scope="col">작성자</th>
                        <th scope="col">작성일</th>
                        <th scope="col">댓글수</th>
                        <th scope="col">연동된 판매</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index">
                            <td class="text-left">
                                {{ truncateContent(item.content) }}
                                <span v-if="item.photos" style="float: right;">
                                    <template v-for="(img, imgIndex) in item.photos.slice(0, 5)">
                                        <img :src="img.url" :key="imgIndex" style="height: 20px;" data-zoomable>&nbsp;
                                    </template>
                                </span>
                            </td>
                            <td>{{ item.author.name }}</td>
                            <td>{{ formatDate(item.author.created_at) }}</td>
                            <td>{{ item.comment_count }}</td>
                            <td>
                                <template v-if="item.postSeq > 0">
                                    <span class="link" @click="onClickModalContentDetail(item)">{{ `일련번호 : ${item.saleSeq}` }}<br/>{{ `등록일 : ${item.saleCreateDatetime}` }}</span>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <!--
                            <td>
                                <template v-if="item.postSeq > 0">
                                    <table class="table inner-table no-mrg link" @click="onClickModalContentDetail(item)">
                                        <tr v-for="(productItem, productIndex) in item.productList" :key="productIndex">
                                            <td class="text-left" style="text-wrap: nowrap;">{{ productItem.name }}</td>
                                        </tr>
                                    </table>
                                </template>
                            </td>
                            -->
                            <td>
                                <button type="button" class="btn btn-secondary btn-xs" @click="onClickBandSalesSave(item)">{{ item.postSeq>0?"재연동하기":"연동하기" }}</button>
                                <template v-if="item.postSeq > 0">
                                &nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onClickBandSalesDelete(item)">연동해제</button>
                                </template>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div>
                <button type="button" class="btn btn-secondary" @click="onClickPrevious()" v-if="previous_params">이전</button>
                <button type="button" class="btn btn-secondary" @click="onClickNext()" v-if="next_params">다음</button>
            </div>
        </div>

        <modal-content-selected 
            ref="modalContentSelected" 
            @onCloseContentSelected="onCloseContentSelected"
		></modal-content-selected>

        <modal-content-delete 
            ref="modalContentDelete" 
            @onCloseContentSelected="onCloseContentSelected"
		></modal-content-delete>

        <!-- 상세보기 -->
        <modal-content-detail 
            ref="modalContentDetail" 
            @onCloseContentDetail="onCloseContentDetail"
		></modal-content-detail>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import MContentSelected from '../../modal/NaverBand/MContentSelected'
import MContentDelete from '../../modal/NaverBand/MContentDelete'
import MContentDetail from '../../modal/Sales/MContentDetail'
import mediumZoom from "medium-zoom";

import { mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'orderList',
    mixins: [tableMixin],
    components: {
        'modal-content-selected': MContentSelected,
        'modal-content-delete': MContentDelete,
        'modal-content-detail'   : MContentDetail,
    },
    data() {
        return {
            userInfo        : {},
            tableList       : [],
            bandList        : [],
            selectBandKey   : '',
            previous_params : {},
            next_params     : {},
            saleList       : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    mounted() {
        //목록 LOAD
        this.saleList = []
        this.getBandList();
        mediumZoom('[data-zoomable]');
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        getTMData() {
            this.tableList = [];
            this.previous_params = {}
            this.next_params = {}

            this.totalCount = 0;

            this.$connect(
				`/api/band/accessInfo/id/${this.userInfo.id}`,
                'GET'
            )
            .then(bodyData => {
                this.accessInfo = bodyData.list[0]

                if(this.accessInfo){
                    this.fatchData(`https://openapi.band.us/v2/band/posts?access_token=${this.accessInfo.accessToken}&band_key=${this.selectBandKey}&locale=ko_KR`)
                }

            })
            .catch(error => {
                console.log("error", error);
            })
        },

        fatchData(url){
            fetch(url, {
                method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(response => response.json())
            .then(data => {
                if(data.result_code === 1){
                    //console.log(data.result_data.items)
                    //this.tableList = data.result_data.items
                    this.onCheckBandSales(data.result_data.items)   //시작일과 종료일을 보내서 값을 찾는다
                    this.tableList = [...this.tableList, ...data.result_data.items];
                    
                    if(data.result_data.paging){
                        this.previous_params = data.result_data.paging?.previous_params
                        this.next_params = data.result_data.paging?.next_params
                    }

                    this.$nextTick(() => {
                        mediumZoom('[data-zoomable]');
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        },

        onClickPrevious(){
            //API에서 아직 제공하지 않음
        },
        onClickNext(){
            this.fatchData(`https://openapi.band.us/v2/band/posts?after=${this.next_params.after}&limit=${this.next_params.limit}&access_token=${this.accessInfo.accessToken}&band_key=${this.next_params.band_key}&locale=ko_KR`)
        },

        getBandList(){
            this.$connect(
				`/api/band/band/list/${this.userInfo.id}`,
                'GET'
            )
            .then(bodyData => {
                this.bandList = bodyData.list
                if(this.bandList[0]){
                    this.selectBandKey = this.bandList[0].bandKey
                    this.getTMData()
                }else{
                    alert("연동된 밴드가 없습니다.\r\n밴드를 연동해주세요.")
                    return false
                }
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        onChangeSelectBandKey(){
            this.getTMData()
        },

        truncateContent(content) {
            const maxLength = 20;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },

        formatDate(timestamp) {
            let date = new Date(timestamp);
            return (
                date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
            );
        },

        onClickBandSalesSave(item){
            this.$refs.modalContentSelected.onShow(item)
        },

        onClickBandSalesDelete(item){
            this.$refs.modalContentDelete.onShow(item)
        },

        /**
         * 등록/수정 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentSelected(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        async onCheckBandSales(items) {
            if (items.length === 0) return;

            // 타임스탬프 배열 생성
            let timestamps = items.map(item => item.created_at);

            if (timestamps.length === 0) return;

            // 최소 및 최대 타임스탬프 계산
            let minTimestamp = Math.min(...timestamps);
            let maxTimestamp = Math.max(...timestamps);

            // 포맷된 날짜 문자열
            const startYearMonth = '202401';  // 임시 값, 실제로는 minTimestamp를 사용해야 할 수도 있습니다.
            const endYearMonth = DateUtils.nowDate().replace(/[^0-9]/g, "").substr(0, 6);

            try {
                // 첫 번째 API 호출
                const bodyData = await this.$connect(
                    `/api/band/post/list/${this.selectBandKey}/${startYearMonth}/${endYearMonth}`,
                    'GET'
                );
                this.saleList = bodyData.list;

                // 각 item의 postSeq를 설정
                for (const item of this.tableList) {
                    const foundItem = this.saleList.find(sale => sale.postKey === item.post_key);
                    if (foundItem && foundItem.seq) {
                        this.$set(item, 'postSeq', foundItem.seq);

                        try {
                            // 두 번째 API 호출
                            const response2 = await axios.get(`${this.domainUrl}/sale/shop/${foundItem.saleToken}`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${this.userInfo.userToken}`
                                }
                            });

                            const saleSeq            = response2.data.seq
                            const saleCreateDatetime = response2.data.createDatetime
                            this.$set(item, 'saleSeq', saleSeq);
                            this.$set(item, 'saleCreateDatetime', this.onFormatDateYmd(saleCreateDatetime));

                            /*
                            //물품 리스트 가져오기 - 현재는 안보여줌
                            try {
                                const response3 = await axios.get(`${this.domainUrl}/api/productInSale/get/${saleSeq}`, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${this.userInfo.userToken}`
                                    }
                                });
                                this.$set(item, 'productList', response3.data.body.list)
                            } catch (error) {
                                //
                            }
                            */
                            
                        } catch (error) {
                            console.error("Error fetching sale data:", error)
                        }

                    }
                }
            } catch (error) {
                console.log("Error fetching band post list:", error);
            }
        },

        /**
         * 상세보기 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentDetail(item) {
            if(item.saleSeq > 0){
                this.$refs.modalContentDetail.onShow({ 'seq' : item.saleSeq })
            }
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentDetail(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString).substr(0, 10)
        },

    }
}
</script>
<style scoped>
.medium-zoom-overlay {
    z-index: 1050;
}
</style>