<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="내 정보" :width="1400">
            <template v-slot:body>
                <div>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">상점정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">상호명</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="상호명을 입력하세요." v-model="userData.name" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">로고</label>
                                    <div class="col-sm-4">
                                        <!--<span class="form-control" >{{ logo }}</span>-->
                                        <div v-if="logo" style="margin-bottom: 5px;"><img :src="logo"  style="height: 50px;"/></div>
                                        <input
                                        type="file"
                                        ref="logoFile"
                                        />
                                        <span v-if="logo" style="position: absolute; bottom: 0; width: 350px;">
                                            <div class="custom-control custom-control-inline" 
                                            style="padding-top: 10px;">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" id="isLogoDel" :checked="isLogoDel" @change="onClickIsLogoDel" />
                                                    <label class="custom-control-label" for="isLogoDel">로고를 삭제합니다.</label>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">대표자명</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="대표자명을 입력하세요." v-model="userData.ceoName" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">연락처</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control" placeholder="연락처를 입력하세요." v-model="userData.phone" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">이메일</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="이메일을 입력하세요." v-model="userData.email" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
                    <hr>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">네이버 밴드 연동</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">네이버 밴드 로그인</label>
                                    <div class="col-sm-4">
                                        <a @click="onClickLoginNaverBand()">
                                            <img src="https://static.nid.naver.com/oauth/big_g.PNG" style="max-width: 200px;">
                                        </a>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                    <hr>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">바로빌 연동</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">바로빌 가입</label>
                                    <div class="col-sm-4">
                                        <template v-if="userData.memberBarobill && userData.memberBarobill[0]?.id">
                                            <span class="form-control">{{ barobillId }}</span>
                                        </template>
                                        <template v-else>
                                            <button type="button" class="btn btn-primary" @click="onClickBarobill">가입하기</button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title" style="position: relative;">
                            <h3 class="text-left">계좌정보</h3>
                            <button type="button" class="bank_add" @click="onClickBankAdd">추가</button>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <template v-for="(item, index) in userData.memberAccount">
                                    <div class="form-group row" style="position: relative;" :key="index">
                                        <div class="bank_remove" v-if="index > 0">
                                            <template v-if="getBarobillRegistAccountState(item)">
                                                <button type="button">연동해제 후 삭제가능</button>
                                            </template>
                                            <template v-else>
                                                <button type="button" @click="onClickBankRemove(index)">삭제</button>
                                            </template>
                                        </div>
                                        <label class="col-sm-1 col-form-label">은행</label>
                                        <div class="col-sm-2">
                                            <select class="form-control" placeholder="은행을 선택하세요." v-model="item.bankName" @change="onChangeBank(item)">
                                                <option value="" disabled>은행 선택</option>
                                                <option 
                                                v-for="(item, index) in bankCodeList"
                                                :key="index"
                                                :value="item.name"
                                                >{{ item.name }}</option>
                                            </select>
                                        </div>
                                        <label class="col-sm-1 col-form-label">예금주</label>
                                        <div class="col-sm-2">
                                            <input type="text" class="form-control" placeholder="예금주를 입력하세요." v-model="item.accountName" />
                                        </div>
                                        <label class="col-sm-1 col-form-label">계좌번호</label>
                                        <div class="col-sm-2">
                                            <input type="text" class="form-control" placeholder="계좌번호를 입력하세요." v-model="item.account" />
                                        </div>
                                        <label class="col-sm-1 col-form-label">계좌구분</label>
                                        <div class="col-sm-1">
                                            <select class="form-control" placeholder="계좌구분 선택" v-model="item.accountType">
                                                <option value="" disabled>계좌구분 선택</option>
                                                <option value="P">개인</option>
                                                <option value="C">법인</option>
                                            </select>
                                        </div>
                                        <template v-if="userData.memberBarobill && userData.memberBarobill[0]?.id && isMemberAdditionalServiceAccount">
                                            <template v-if="getBarobillRegistAccountState(item)">
                                                <div class="col-sm-1">
                                                    <span style="
                                                    font-size: 90%;
                                                    padding-left: 1px;
                                                    padding-right: 1px;
                                                    text-align: center;
                                                    text-wrap: nowrap;
                                                    line-height: 35px;
                                                    color: red;
                                                    ">* 입금 확인 서비스 사용중</span>
                                                </div>
                                                <!--
                                                <div class="col-sm-1">
                                                    <button type="button" class="btn btn-secondary" @click="onClickBarobillTerminateAccount(userData, item, index)">연동 해지</button>
                                                </div>
                                                -->
                                            </template>
                                            <template v-else>
                                                <div class="col-sm-1">
                                                    <button type="button" class="btn btn-secondary" @click="onClickBarobillRegistAccount(userData, item, index)">서비스 연동</button>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">사업자 정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">사업자등록번호</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control" placeholder="사업자등록번호를 입력하세요." v-model="userData.businessNumber" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">통신판매업신고번호</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="통신판매업신고번호를 입력하세요." v-model="userData.mailOrderSalesRegistrationNumber" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">업태</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="업태를 입력하세요." v-model="userData.businessType" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">업종</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="업종을 입력하세요." v-model="userData.businessItem" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">사업장 주소</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" placeholder="사업장 주소를 입력하세요." v-model="userData.address" /><br>
                                        <input type="text" class="form-control" placeholder="사업장 상세 주소를 입력하세요." v-model="userData.addressDetail" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">비밀번호 변경</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">변경여부</label>
                                    <div class="col-sm-4">
                                        <div class="custom-control custom-control-inline" 
                                        style="padding-top: 10px;">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="isPasswordChange" :checked="isPasswordChange" @change="onClickIsPasswordChange" />
                                                <label class="custom-control-label" for="isPasswordChange">비밀번호를 변경합니다.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label">변경할 비밀번호</label>
                                    <div class="col-sm-4">
                                        <form autocomplete="off" style="width:100%;">
                                        <input type="text" name="username" autocomplete="off" style="display:none;">
                                        <input type="password" class="form-control" placeholder="변경할 비밀번호를 입력하세요." :disabled="!isPasswordChange" v-model="userData.password" autocomplete="new-password" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickContentSave">변경 저장</button> 
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

        <!--확인-->
        <modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
        ></modal-content-save>

        <!--바로빌 연동-->
        <modal-bank-service-save 
            ref="modalBankServiceSave" 
            @onCloseBarobillRegistAccount = "onCloseBarobillRegistAccount"
		></modal-bank-service-save>

        <!--바로빌 연동 해지-->
        <modal-bank-service-del 
            ref="modalBankServiceDel" 
            @onCloseBarobillRegistAccount = "onCloseBarobillRegistAccount"
		></modal-bank-service-del>


    </div>
</template>

<script>
import Modal from '../Modal'

import MContentSave from './MContentSave'
import * as DateUtils from '../../utils/date-utils'
import { mapState } from 'vuex'
import axios from 'axios'

import MBankServiceSave from './MBankServiceSave'
import MBankServiceDel from './MBankServiceDel'

export default {
    components: {
        Modal,
        'modal-content-save'      : MContentSave,
        'modal-bank-service-save' : MBankServiceSave,
        'modal-bank-service-del'  : MBankServiceDel,
    },
    data() {
        return {
            logo                             : '',
            userInfo                         : {},
            userData                         : {},
            orgUserData                      : {},
            isPasswordChange                 : false,

            serviceStartDatetime             : "",
            serviceEndDatetime               : "",
            bankCodeList                     : [],
            
            orgMemberAdditionalService       : [],
            isMemberAdditionalServiceAccount : false,
            barobillRegistAccountData        : {},
            isMemberAdditionalServiceSeq     : 0,
            barobillId                       : '',
            isLogoDel : false,
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow() {
            this.userData                         = {}
            this.orgUserData                      = {}
            this.isPasswordChange                 = false
            this.logo                             = ''
            this.bankCodeList                     = []
            this.barobillRegistAccountData        = []
            this.isMemberAdditionalServiceAccount = false
            this.orgMemberAdditionalService       = []
            this.barobillId                       = ''
            this.serviceStartDatetime             = ""
            this.serviceEndDatetime               = ""

            this.isMemberAdditionalServiceSeq     = 0
            this.isLogoDel = false

            this.getBankCodeList()
            this.getTMData()
            this.$refs.modal.onShow()
        },
        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.userData = {}
            this.$connect(
				`/api/member/${this.userInfo.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                this.orgUserData                = JSON.parse(JSON.stringify(bodyData.list))
                this.memberAccount              = JSON.parse(JSON.stringify(this.userData.memberAccount))
                this.serviceStartDatetime       = this.userData.serviceStartDatetime?.substr(0, 10)
                this.serviceEndDatetime         = this.userData.serviceEndDatetime?.substr(0, 10)
                this.barobillId                 = this.userData.memberBarobill[0]?.id?this.userData.memberBarobill[0]?.id:''
                this.orgMemberAdditionalService = JSON.parse(JSON.stringify(this.orgUserData.memberAdditionalService))
                this.logo                       = this.userData.logo

                const depositService = this.orgMemberAdditionalService.find(service => service.name.includes('입금'));
                this.isMemberAdditionalServiceAccount = depositService?depositService.seq : false
                this.isMemberAdditionalServiceSeq     = depositService?.serviceSeq?depositService?.serviceSeq:0
                
                if(this.userData.memberAccount.length < 1){
                    this.userData.memberAccount.push({
                        bankName : '',
                        accountName: '',
                        account: ''
                    })
                }

                this.getBarobillRegistAccount()

            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method onClickContentSave
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        onClickContentSave() {

            if(
                !this.userData.name || 
                !this.userData.ceoName || 
                !this.userData.businessNumber || 
                !this.userData.businessType || 
                !this.userData.businessItem || 
                !this.userData.address || 
                !this.userData.addressDetail || 
                !this.userData.phone || 
                !this.userData.email
            ){
                alert("필요한 정보가 입력되지 않았습니다.\r\n[상호명] [대표자명] [사업자등록번호] [업종] [업태]\r\n[사업장 주소] [사업장 상세주소] [연락처] [이메일]\r\n위의 정보를 입력해주세요.")
                return
            }
            
            let uploadFiles = this.$refs.logoFile.files;
            let formData = new FormData();
            formData.append("file", uploadFiles[0]);

            this.userData.memberAccount = this.userData.memberAccount.filter(item => item.bankName || item.accountName || item.account);
            this.$refs.modalContentSave.onShow({
                'userData'    : this.userData,
                'formData'    : formData,
                'uploadFiles' : uploadFiles,
                'isLogoDel'   : this.isLogoDel
            })
        },
        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
                this.$refs.logoFile.value = ''
            }
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateKor(dateString)
        },

        /**
         * 비빌번호 변경 여부
         *
         * @method onClickIsPasswordChange
         */
        onClickIsPasswordChange(){
            this.isPasswordChange = !this.isPasswordChange
        },

        onClickIsLogoDel(){
            this.isLogoDel = !this.isLogoDel
        },

        /**
         * 은행코드 넣기
         *
         * @method onChangeBank
         */
        onChangeBank(item){
            const selectedBank = this.bankCodeList.find(bank => bank.name === item.bankName);
            if (selectedBank) {
                item.bankCode = selectedBank.code;
            } else {
                item.bankCode = '';
            }
        },
        
        onClickBankAdd(){
            this.userData.memberAccount.push({
                bankName : '',
                accountName: '',
                account: ''
            })
        },

        onClickBankRemove(index){
            this.userData.memberAccount.splice(index, 1);
        },

        onGetServiceType(number){
            //console.log("onGetServiceType:", number)
            if(number && number > 0){
			    return this.$store.getters.getServiceType(number);
            }
		},

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
        getBankCodeList(){
            this.$connect(
				`/api/bankcode/listAll`,
                'GET'
            )
            .then(bodyData => {
                this.bankCodeList = bodyData.list
                //console.log("this.bankCodeList:", this.bankCodeList)
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
         getBarobillRegistAccount(){
            this.$connect(
				`/api/member/getBarobillRegistAccount/${this.userData.id}`,
                'GET'
            )
            .then(bodyData => {
                this.barobillRegistAccountData = bodyData.list
                //console.log("getBarobillRegistAccount:", bodyData.list)
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        getBarobillRegistAccountState(item){
            //console.log("this.barobillRegistAccountData:", this.barobillRegistAccountData)
            if(this.barobillRegistAccountData && this.barobillRegistAccountData.length > 0){
                const accountData = this.barobillRegistAccountData.find(
                    data => data.account === item.account
                );
                if (accountData && accountData.isRegist) {
                    return accountData.isRegist === 'R' ? true : false;
                }
                return false;
            }else{
                return false;
            }
        },

        onClickBarobillRegistAccount(userData, item, index){
            if(
                !item.account || 
                !item.accountName || 
                !item.accountType || 
                !item.bankName || 
                !item.seq
            ){
                alert("계좌 정보의 전체 입력 후 연동해주세요")
                return false
            }

            if(!item.accountType){
                alert("연동을 위한 정보를 입력해주세요.")
                return false
            }

            if(confirm("바로빌 입금 확인 위한 계좌 연동은\r계좌당 1만원/1개월의 추가비용이 발생합니다.\r계속 진행하시겠습니까?")){
                this.onClickSave()
                
                this.$refs.modalBankServiceSave.onShow({
                    "userData"             : userData,
                    "item"                 : item,
                    "additionalServiceSeq" : this.isMemberAdditionalServiceSeq
                })
            }

        },

        onCloseBarobillRegistAccount(payload){
            if(payload.isUpdate){
                this.getTMData()
            }
        },

        onClickBarobillTerminateAccount(userData, item, index){
            if(
                !item.account || 
                !item.accountName || 
                !item.accountType || 
                !item.bankName || 
                !item.seq
            ){
                alert("계좌 정보의 전체 입력 후 연동 해지해주세요")
                return false
            }

            if(!item.accountType){
                alert("연동 해지를 위한 정보를 입력해주세요.")
                return false
            }
            if(confirm("바로빌 입금 확인 위한 계좌 연동을 해지 하시겠습니까?")){
                this.onClickSave()
                this.$refs.modalBankServiceDel.onShow({
                    "userData"             : userData,
                    "item"                 : item,
                    "additionalServiceSeq" : this.isMemberAdditionalServiceSeq
                })
            }

        },

        onClickBarobill(){
            if(confirm("바로빌 가입을 진행하시겠습니까?")){
                if(
                    !this.orgUserData.name || 
                    !this.orgUserData.ceoName || 
                    !this.orgUserData.businessNumber || 
                    !this.orgUserData.businessType || 
                    !this.orgUserData.businessItem || 
                    !this.orgUserData.address || 
                    !this.orgUserData.addressDetail || 
                    !this.orgUserData.phone || 
                    !this.orgUserData.email
                ){
                    alert("바로빌 가입을 위한 정보가 입력되지 않았습니다.\r\n[상호명] [대표자명] [사업자등록번호] [업종] [업태]\r\n[사업장 주소] [사업장 상세주소] [연락처] [이메일]\r\n위의 정보를 저장 후 바로빌 아이디를 가입해주세요.")
                    return
                }
                this.onClickSave()
                this.$connect(
                    //`/api/member/barobillJoin/${this.barobillId}`,
                    `/api/member/barobillJoin/${this.userInfo.id}`,  //회원의 아이디를 보냄
                    'GET'
                )
                .then(bodyData => {
                    if(bodyData){
                        alert("바로빌에 가입되었습니다.")
                        this.getTMData()
                    }
                })
            }
        },

        async onClickSave() {
            try {
                this.userData.memberAccount = this.userData.memberAccount.filter(item => item.bankName || item.accountName || item.account);

                // 필요없는 필드 제거
                delete this.userData.accountNonExpired;
                delete this.userData.accountNonLocked;
                delete this.userData.createDatetime;
                delete this.userData.modifyDatetime;
                delete this.userData.userToken;
                delete this.userData.authorities;
                delete this.userData.credentialsNonExpired;

                // 비밀번호 설정
                if (!this.userData.password) {
                    this.userData.password = this.userInfo.password;
                }

                // API 호출
                const bodyData = await this.$connect(
                    `/api/member`,
                    'POST',
                    this.userData
                );
            } catch (error) {
                console.error("Error:", error);
                alert("설정을 저장하는 중 오류가 발생했습니다.");
            }
        },

        /*
        async onClickLogoFileUpload(){
            try {
                let uploadFiles = this.$refs.logoFile.files;
                let formData = new FormData();
                formData.append("file", uploadFiles[0]);

                const response = await axios.post(`${this.domainUrl}/api/file/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.userInfo.userToken}`
                    }
                });
                console.log(this.logo = response.data);
            } catch (error) {
                console.log(error);
            }
        },
        */

        onClickLoginNaverBand(){
            const clientId    = '431868360';
            const redirectUri = encodeURIComponent('http://goldcarriageshop.kr/naverBand/naverBand.php');
            const authUrl = `https://auth.band.us/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
            window.location.href = authUrl;
        },
    },
}
</script>