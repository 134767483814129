<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="`상품 상세보기`" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">상품명</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.name }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">단가</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onNumberFormat(modalData.unitPrice) }}원</span>
							</div>
                            <label class="col-sm-2 col-form-label">주문단위</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onNumberFormat(modalData.unitQuantity) }}</span>
							</div>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">재고 사용유무</label>
							<div class="col-sm-4">
								<span class="form-control">{{ (modalData.isUseStock === 1)?"재고 사용":"재고 사용 안함" }}</span>
							</div>
                            <template v-if="modalData.isUseStock === 1">
                                <label class="col-sm-2 col-form-label">재고</label>
                                <div class="col-sm-4">
                                    <span class="form-control">{{ onNumberFormat(modalData.goldCarriageAvailableStock) }}</span>
                                </div>
                            </template>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">부가세</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.vat === 'VAT' ? '부가세' : modalData.vat }}</span>
							</div>
						</div>
                        <!--
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">배송방법</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.deliveryType }}</span>
							</div>
							<label class="col-sm-2 col-form-label">배송구분</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.deliveryPayType }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">배송비</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onNumberFormat(modalData.deliveryFee) }}원</span>
							</div>
						</div>
                        -->
                        <!--
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">입력 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.createUserId }}</span>
							</div>
							<label class="col-sm-2 col-form-label">입력일</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.createDatetime) }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">수정 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.modifyUserId }}</span>
							</div>
							<label class="col-sm-2 col-form-label">수정일</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.modifyDatetime) }}</span>
							</div>
						</div>
                        -->
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
        }
    },
    methods: {
        onShow(payload) {
            this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
            this.getModalData();
        },

        getModalData() {
			this.$connect(
                `/api/product/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDetail', {
                isUpdate: this.isUpdate
            })
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },
    },
}
</script>