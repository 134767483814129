<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '상품 등록' : '상품 수정'" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">상품명</label>
							<div class="col-sm-10">
								<input type="text" class="form-control" placeholder="상품명을 입력하세요." v-model="modalData.name"/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">단가</label>
							<div class="col-sm-4">
								<input type="number" class="form-control" placeholder="단가를 입력하세요." v-model="modalData.unitPrice" />
							</div>
							<label class="col-sm-2 col-form-label">주문단위</label>
							<div class="col-sm-4">
								<input type="number" class="form-control" placeholder="주문단위를 입력하세요." value="1" readonly />
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">재고 사용유무</label>
							<div class="col-sm-4">
								<select class="form-control" v-model="modalData.isUseStock" placeholder="재고 사용유무를 선택하세요.">
									<option value="1">재고 사용</option>
									<option value="0">재고 사용안함</option>
								</select>
							</div>
							<template v-if="modalData.isUseStock === '1' || modalData.isUseStock === 1">
								<label class="col-sm-2 col-form-label">재고</label>
								<div class="col-sm-4">
									<input type="number" class="form-control" placeholder="주문단위를 입력하세요." v-model="modalData.goldCarriageAvailableStock"/>
								</div>
							</template>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">부가세</label>
							<div class="col-sm-4">
								<select class="form-control" v-model="modalData.vat" placeholder="부가세를 선택하세요.">
									<option value="VAT">부가세</option>
									<option value="면세">면세</option>
								</select>
							</div>
						</div>
						<!--
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">배송방법</label>
							<div class="col-sm-4">
								<select class="form-control" v-model="modalData.deliveryType" placeholder="배송방법을 선택하세요.">
									<option value="택배">택배</option>
									<option value="픽업">픽업</option>
									<option value="용차">용차</option>
									<option value="직배">직배</option>
								</select>
							</div>
							<label class="col-sm-2 col-form-label">배송구분</label>
							<div class="col-sm-4">
								<select class="form-control" v-model="modalData.deliveryPayType" placeholder="배송구분을 선택하세요.">
									<option value="무료">무료</option>
									<option value="선불">선불</option>
									<option value="착불">착불</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">배송비</label>
							<div class="col-sm-4">
								<input type="number" class="form-control" placeholder="배송비를 입력하세요." v-model="modalData.deliveryFee" />
							</div>
						</div>
						-->
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
			mode      : 's',
			userInfo  : {},
			channel   : '',
        }
    },
	created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
		this.channel = this.$store.getters.getChannel
    },
    methods: {
        onShow(payload) {
			if(payload && payload.seq){
				this.modalData = JSON.parse(JSON.stringify(payload))
				this.getModalData();
				this.mode = 'e'
			}else{
				this.modalData = []
			}
            this.$refs.modal.onShow()
        },

        getModalData() {
			this.$connect(
                `/api/product/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
				this.modalData.unitQuantity = 1
            })
		},

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSave', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {
			if(!this.modalData.name){
				alert("상품명을 입력하세요.")
				return false
			}
			if(!this.modalData.unitPrice){
				alert("단가를 입력하세요.")
				return false
			}
			if(!this.modalData.vat){
				alert("부가세를 선택하세요.")
				return false
			}
			/*
			if(!this.modalData.deliveryType){
				alert("배송방법을 선택하세요.")
				return false
			}
			if(!this.modalData.deliveryPayType){
				alert("배송구분을 선택하세요.")
				return false
			}
			if(!this.modalData.deliveryFee){
				alert("배송비를 입력하세요.")
				return false
			}
			*/

			let param = this.modalData

			param = {
				"businessType"               : this.channel,
				'name'                       : this.modalData.name,
				'unitPrice'                  : this.modalData.unitPrice,
				'deliveryType'               : this.modalData.deliveryType,
				'deliveryPayType'            : this.modalData.deliveryPayType,
				'deliveryFee'                : this.modalData.deliveryFee,
				"unitQuantity"               : 1,
				"vat"                        : this.modalData.vat,
				"unit"                       : this.modalData.unit,
				"ordering"                   : this.modalData.ordering,
				"migongAvailableStock"       : 0,
				"migongSaleCount"            : 0,
				"goldCarriageAvailableStock" : this.modalData.goldCarriageAvailableStock?this.modalData.goldCarriageAvailableStock:0,
				"goldCarriageSaleCount"      : 0,
				"isView"                     : 0,
				"productImage"               : [],
				"productOption"              : [],
				"createUserId"               : this.modalData.createUserId?this.modalData.createUserId:this.userInfo.id,
				"isUseStock"                 : parseInt(this.modalData.isUseStock),
			}

			if(this.mode === 'e'){
				param.seq          = this.modalData.seq
                param.modifyUserId = this.userInfo.id
			}

			this.$connect(
                `/api/product`,
                'POST', param
            )
            .then(bodyData => {
				alert("상품이 " + (this.mode === 's'?"등록":"수정") + " 되었습니다.")
				this.isUpdate = true
				this.onClickClose()
            })

		},

		/**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        }
    },
}
</script>