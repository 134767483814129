import { render, staticRenderFns } from "./MBankServiceDel.vue?vue&type=template&id=4db4fa32&scoped=true&"
import script from "./MBankServiceDel.vue?vue&type=script&lang=js&"
export * from "./MBankServiceDel.vue?vue&type=script&lang=js&"
import style0 from "./MBankServiceDel.vue?vue&type=style&index=0&id=4db4fa32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db4fa32",
  null
  
)

export default component.exports