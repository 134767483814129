<template>
    <div>
        <div class="boxwrap">
            <div class="searchwrap">
                <form>
                    <div class="form-row align-items-center">
                        <label class="col-form-label">주문일자</label>
                        <!-- 기간 -->
                        <term-date-picker-string 
						@onChangeDate="setTMDate" 
						:is-show-term="true" 
						:key="viewKey" 
						:defaultTermIndex="-111"
						></term-date-picker-string>
                    </div>
                    <div class="form-row align-items-center">
                        <label class="col-form-label">검색어</label>
                        <select-box
                            ref="selectBox"
                            :selectBoxs="[
                                { name: '주문자 이름', value: 'NAME' },
                                { name: '입금자명', value: 'DEPOSITOR' },           
                                { name: '상품명', value: 'PRODUCT' },     
                                { name: '주문자 전화번호', value: 'PHONE' },
                                { name: '배송', value: 'DELIVERY' }
                            ]"
                            :default-select-box-value="searchType"
                            @onChangeSearchBox="setSelectBox"
                            :key="viewKey + 1"
                        ></select-box>
                        <div class="col-auto ssb-search w-25">
                            <input v-model="searchText" class="form-control" type="text" placeholder="검색어를 입력하세요" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary btn-search" @click="onClickSearch">검색</button>&nbsp;
                        <button type="button" class="btn btn-secondary btn-search" @click="onClickClear">전체검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="title" style="position: relative;">
                <div class="option-btn mrg-left-10">
					<select class="form-control" v-model="tableListRow" @change="onChangeListRow">
                        <option value="10">10개씩 보기</option>
						<option value="20">20개씩 보기</option>
						<option value="40">40개씩 보기</option>
						<option value="80">80개씩 보기</option>
						<option value="100">100개씩 보기</option>
					</select>
				</div>
                <div class="option-btn mrg-left-10">
                    <a href="javascript:void(0)" class="btn btn-secondary btn-sm" @click="onClickExcel">
                        <img src="@/assets/img/icon-excel.png" alt />&nbsp;엑셀 다운로드
                    </a>
                </div>
                <div class="option-btn mrg-left-10" v-if="userInfo.serviceType > 1">
                    <div class="total_box">주문 금액 합계 : <span>{{ onNumberFormat(amountSum) }}원</span></div>
                </div>
                <div class="orderStatusBox">
                    <div>주문 : <span>{{ onNumberFormat(orderCount) }}</span></div>
                    <div>입금 : <span>{{ onNumberFormat(depositCount) }}</span></div>
                    <div>취소 : <span>{{ onNumberFormat(cancelCount) }}</span></div>
                </div>
                <h3 class="text-left">목록 (총
                    <strong class="point">{{ totalCount }}</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col">주문번호</th>
                        <th scope="col">상품</th>
                        <th scope="col">수량</th>
                        <th scope="col">단가</th>
                        <th scope="col">합계</th>
                        <th scope="col">수령방법</th>
                        <th scope="col">주문일</th>
                        <th scope="col">주문자(닉네임)</th>
                        <th scope="col">연락처</th>
                        <th scope="col">입금자명</th>
                        <th scope="col">총주문 금액</th>
                        <th scope="col">주문상태</th>
                        <th scope="col" v-if="isCashReceipt">현금영수증</th>
                        <th scope="col">관리</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index" class="link">
                            <td @click="onClickModalContentDetail(item)">{{ item.seq }}</td>
                            <td class="no-pdd" style="text-wrap: nowrap;" @click="onClickModalContentDetail(item)">
                                <table class="table inner-table no-mrg">
                                    <tr v-for="(orderItem, orderIndex) in item.orderProduct" :key="orderIndex">
                                        <td class="text-left" style="text-wrap: nowrap;">{{ orderItem.name }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td class="no-pdd" @click="onClickModalContentDetail(item)">
                                <table class="table inner-table no-mrg">
                                    <tr v-for="(orderItem, orderIndex) in item.orderProduct" :key="orderIndex">
                                        <td>{{ onNumberFormat(orderItem.quantity) }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td class="no-pdd" @click="onClickModalContentDetail(item)">
                                <table class="table inner-table no-mrg">
                                    <tr v-for="(orderItem, orderIndex) in item.orderProduct" :key="orderIndex">
                                        <td>{{ onNumberFormat(orderItem.unitPrice) }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td class="no-pdd" @click="onClickModalContentDetail(item)">
                                <table class="table inner-table no-mrg">
                                    <tr v-for="(orderItem, orderIndex) in item.orderProduct" :key="orderIndex">
                                        <td>{{ onNumberFormat(orderItem.amount) }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td class="no-pdd" @click="onClickModalContentDetail(item)">
                                <table class="table inner-table no-mrg">
                                    <tr v-for="(orderItem, orderIndex) in item.orderProduct" :key="orderIndex">
                                        <td style="text-wrap: nowrap;">{{ orderItem.deliveryType }}</td>
                                    </tr>
                                </table>
                            </td>
							<td @click="onClickModalContentDetail(item)">{{ onFormatDateYmd(item.createDatetime) }}</td>
							<td @click="onClickModalContentDetail(item)" style="text-wrap: nowrap;">{{ item.name }}</td>
							<td @click="onClickModalContentDetail(item)">{{ item.phone }}</td>
                            <td @click="onClickModalContentDetail(item)" style="text-wrap: nowrap;">{{ item.depositorName }}</td>
							<td @click="onClickModalContentDetail(item)">{{ onNumberFormat(item.amount) }}</td>
							<td>
                                <template v-if="userInfo.serviceType > 1">
                                    <select v-model="item.status" class="form-control" @change="onChangeStaus(item)" style="min-width: 80px;">
                                        <option value="주문">주문</option>
                                        <option value="입금완료">입금완료</option>
                                        <option value="수령전">수령 전</option>
                                        <option value="수령완료">수령 완료</option>
                                        <option value="정산완료">정산 완료</option>
                                        <option value="취소">취소</option>
                                    </select>
                                </template>
                                <template v-else>
                                    {{ item.status }}
                                </template>
                            </td>
                            <td v-if="isCashReceipt">
                                <template v-if="item.orderCashReceipt && item.orderCashReceipt?.length > 0">
                                    <template v-if="item.orderCashReceipt[item.orderCashReceipt.length-1]?.tradeType === 'N'">
                                        <button type="button" class="btn btn-secondary btn-xs" @click="onClickCancelCashReceipt(item)">취소</button>
                                    </template>
                                    <template v-else>
                                        <button type="button" class="btn btn-secondary btn-xs" @click="onClickCashReceipt(item)">발행</button>
                                    </template>
                                </template>
                                <template v-else>
                                    <button type="button" class="btn btn-secondary btn-xs" @click="onClickCashReceipt(item)">발행</button>
                                </template>
                            </td>
                            <td>
                                <button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentSave(item)">수정</button>
                                <template v-if="item.status !=='취소'">
                                &nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onChangeStatus(item)">취소</button>
                                </template>
                                &nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onClickDelete(item)">삭제</button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <paging-nations 
			ref="pagingNations" 
			:total-content-count="totalCount" 
			:row-per-page=parseInt(tableListRow)
			v-on:onClickPage="getTMPageNum" 
			:key="pagingViewKey" 
			v-show="totalCount !== 0"
			></paging-nations>
        </div>

		<!-- 상세보기 -->
        <modal-content-detail 
            ref="modalContentDetail" 
            @onCloseContentDetail="onCloseContentDetail"
		></modal-content-detail>

        <!--등록/수정-->
		<modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
		></modal-content-save>

        <!--삭제-->
		<modal-content-delete 
            ref="modalContentDelete" 
            @onCloseContentDelete="onCloseContentDelete"
		></modal-content-delete>

        <!--삭제-->
		<modal-cash-receipt-save 
            ref="modalCashReceiptSave" 
            @onCloseContentDelete="onCloseContentDelete"
		></modal-cash-receipt-save>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

import TermDatePickerString from '../../components/TermDatePickerString'
import Pagingnations from '../../components/Pagingnations'
import SelectBox from '../../components/SelectBox'

import { mapState } from 'vuex'
import axios from 'axios'
import { saveAs } from 'file-saver';

import MContentSave from '../../modal/Order/MContentSave'
import MContentDetail from '../../modal/Order/MContentDetail'
import MContentDelete from '../../modal/Order/MContentDelete'
import MCashReceiptSave from '../../modal/Order/MCashReceiptSave'

export default {
    name: 'orderList',
    mixins: [tableMixin],
    components: {
        'term-date-picker-string': TermDatePickerString,
        'select-box'             : SelectBox,
        'paging-nations'         : Pagingnations,
		'modal-content-detail'   : MContentDetail,
        'modal-content-save'     : MContentSave,
        'modal-content-delete'   : MContentDelete,
        'modal-cash-receipt-save': MCashReceiptSave,
    },
    data() {
        return {
            searchType    : 'NAME',
            searchText    : '',
            totalCount    : 0,
            tableList     : [],
            tableListRow  : 10,
            userInfo      : {},
            amountSum     : 0,
            orderCount    : 0,
            depositCount  : 0,
            cancelCount   : 0,
            isCashReceipt : false
        }
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        
        if(this.userInfo.memberAdditionalService.some(service => service.name.includes("현금"))){
            this.isCashReceipt = true
        } else {
            this.isCashReceipt = false
        }
    },
    mounted() {
        //목록 LOAD
        this.getTMData();
    },

    methods: {
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            const param = {
                "startDate"        : this.searchData.startDate.substr(0, 10),
                "endDate"          : this.searchData.endDate.substr(0, 10),
                "searchType"       : this.searchText?this.searchType:"",
                "searchText"       : this.searchText,
                "orderingParam"    : "",
                "orderingAscending": ""
            }
            //console.log("param:", param)
            this.$connect(
				`/api/order/list/${this.searchData.currentPage}/${this.tableListRow}`,
                'POST', param
            )
            .then(bodyData => {
                
                this.tableList  = bodyData.list.content;

                //console.log("this.tableList:", this.tableList)

                this.totalCount = bodyData.totalCount;

                //합계
                this.$connect(
                    `/api/order/sum`,
                    'POST', param
                )
                .then(sumBodyData => {
                    this.amountSum    = sumBodyData.list.amountSum
                    this.orderCount   = sumBodyData.list.orderCount
                    this.depositCount = sumBodyData.list.depositCount
                    this.cancelCount  = sumBodyData.list.cancelCountsum
                })
                .catch(error => {
                    console.log("error", error);
                })

            })
            .catch(error => {
                console.log("error", error);
            })
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		onClickModalContentDetail(item) {
            this.$refs.modalContentDetail.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentDetail() {},

		onChangeOrderState(item){

		},

        /**
         * 목록 보여지는 ROW 수 변경 함수
         *
         * @method onChangeListRow
         * @param 
         */
        onChangeListRow(){
            this.getTMData()
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
        onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
        onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString).substr(0, 10)
        },

        onClickExcel(){
            const param = {
                "startDate"        : this.searchData.startDate.substr(0, 10),
                "endDate"          : this.searchData.endDate.substr(0, 10),
                "searchType"       : this.searchText?this.searchType:"",
                "searchText"       : this.searchText,
                "orderingParam"    : "",
                "orderingAscending": ""
            }
            //console.log(param)
            axios.post(`${this.domainUrl}/api/order/orderExcel`, param, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.userInfo.userToken}`
                },
                responseType: 'blob'
            })
            .then(response => {
                const startDateNum = this.searchData.startDate.replace(/-/g, '')
                const endDateNum   = this.searchData.endDate.replace(/-/g, '')
                const filename     = startDateNum + '_' + endDateNum + '_order' + '.xlsx'
                saveAs(response.data, filename)
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 셀렉트 박스 값을 설정하는 함수
         *
         * @method setSelectBox
         * @param {Object} payload 셀렉트 박스 관련 데이터를 담은 객체
         */
         setSelectBox(payload){
            this.searchType  = payload.selectValue
            this.searchText = ''
        },

        /**
         * 검색 함수
         *
         * @method onClickSearch
         */
         onClickSearch() {
            this.getTMData();
            this.$refs.pagingNations.currentPage = 1;
        },

        /**
         * 초기화(전체검색) 함수
         *
         * @method onClickClear
         */
        onClickClear() {
            this.searchType  = 'NAME'
            this.searchText = ''
            this.$refs.selectBox.setSelectedValue(this.searchType);

            this.totalCount = 0
            this.tableList = []
            this.getTMData()
        },

        onChangeStatus(item){
            if(confirm("주문을 취소하시겠습니까?")){
                item.status = '취소'
                this.$connect(
                    `/api/order`,
                    'POST', item
                )
                .then(bodyData => {
                    this.getTMData()
                })
            }
        },

        /**
         * 등록/수정 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentSave(item) {
            this.$refs.modalContentSave.onShow(item)
        },

        /**
         * 등록/수정 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 현금영수증 신청
         *
         * @method onClickCashReceipt
         */
        onClickCashReceipt(item){
            this.$refs.modalCashReceiptSave.onShow(item)
            /*
            this.$connect(
				`/api/order/cashReceipt/${item.seq}`,
                'GET',
            )
            .then(bodyData => {
                if(bodyData.list){
                    this.getTMData()
                }
            })
            .catch(error => {
                console.log("error", error);
            })
            */
        },

        /**
         * 현금영수증 취소
         *
         * @method onClickCancelCashReceipt
         */
        onClickCancelCashReceipt(item){
            this.$connect(
				`/api/order/cancelCashReceipt/${item.seq}`,
                'GET',
            )
            .then(bodyData => {
                this.getTMData()
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        onChangeStaus(item){
            this.$connect(
                `/api/order`,
                'POST', item
            )
            .then(bodyData => {
                this.getTMData()
            })
        },

        onClickDelete(item){
            this.$refs.modalContentDelete.onShow(item)
        },

        /**
         * 삭제 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentDelete(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },
    }
}
</script>
<style scoped>
.inner-table {
    border: none;
    height: 100%;
}
.inner-table td {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}
.inner-table td:first-child {
    border-left: none;
}
.inner-table td:last-child {
    border-right: none;
}
.inner-table tr:nth-child(n+2) td {
    border-top: 1px dashed #dee2e6;
}
.orderStatusBox {
    position: absolute;
    display: flex;
    left: 140px;
    top: 10px
}
.orderStatusBox div {
    background-color: rgb(212, 146, 3);
    font-weight: 400;
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 10px;
    margin: 0 10px 0 0;
    color: #FFF;
}
.orderStatusBox div:nth-child(2) {
    background-color: rgb(126, 6, 182)
}
.orderStatusBox div:nth-child(3) {
    background-color: rgb(207, 60, 6)
}
.orderStatusBox div span {
    font-weight: 800;
}
</style>