<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="현금영수증 발행" :width="600">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">주문번호</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.seq }}</span>
							</div>
							<label class="col-sm-2 col-form-label">총주문 금액</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onNumberFormat(modalData.amount) }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">전화번호</label>
							<div class="col-sm-10">
								<input type="number" class="form-control" placeholder="주문단위를 입력하세요." v-model="modalData.phone"/>
							</div>
						</div>
					</div>
				</div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickOK">발행</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '../Modal'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
        }
    },
    methods: {
        onShow(payload) {
            this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDelete', {
                isUpdate: this.isUpdate
            })
        },

        onClickOK() {
            this.$connect(
				`/api/order/cashReceipt/${this.modalData.seq}`,
                'GET',
            )
            .then(bodyData => {
                if(bodyData.list){
					//console.log(bodyData.list)
					//전화번호
					this.$connect(
						`/api/order/cashReceipt/${this.modalData.seq}/${this.modalData.phone}`,
						'GET',
					)
					.then(bodyData2 => {
						if(bodyData2.list){
							this.isUpdate = true
							this.onClickClose()
						}
					})
					.catch(error => {
						console.log("error", error);
					})
					
                }else{
					alert("현금영수증 발행에 실패했습니다.")
					this.onClickClose()
				}
            })
            .catch(error => {
                console.log("error", error);
            })
		},

		/**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
		 onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },
    },
}
</script>