<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="`주문 상세보기`" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">주문번호</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.seq }}</span>
							</div>
							<label class="col-sm-2 col-form-label">주문 일시</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.createDatetime) }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">주문자 이름</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.name }}</span>
							</div>
							<label class="col-sm-2 col-form-label">주문자 전화번호</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.phone }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">입금자명</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.depositorName }}</span>
							</div>
						</div>
						<!--
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">주문자 주소</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.address }}</span>
							</div>
						</div>
						-->
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">요청사항</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.memo }}</span>
							</div>
						</div>
						
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">총주문 금액</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onNumberFormat(modalData.amount) }}</span>
							</div>
							<label class="col-sm-2 col-form-label">상태</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.status }}</span>
							</div>
						</div>
						
						<div class="form-group row">
							<div class="col-sm-12">
								<table class="table table-bordered no-mrg text-center">
                                    <colgroup>
                                        <col />
                                        <col width="80" />
                                        <col width="80" />
                                        <col width="80" />
                                        <col width="100" />
										<!--
                                        <col width="80" />
                                        <col width="80"/>
										-->
                                    </colgroup>
									<thead>
										<tr>
											<th>상품명</th>
											<th>주문수</th>
											<th>상품가격</th>
											<th>총액</th>
											<th>배송방법</th>
											<!--
											<th>배송구분</th>
											<th>배송비</th>
											-->
										</tr>
									</thead>
									<tbody>
										<tr v-for="(orderItem, orderIndex) in modalData.orderProduct" :key="orderIndex">
											<td>{{ orderItem.name }}</td>
											<td>{{ onNumberFormat(orderItem.quantity) }}</td>
											<td>{{ onNumberFormat(orderItem.unitPrice) }}</td>
											<td>{{ onNumberFormat(orderItem.amount) }}</td>
											<td>{{ orderItem.deliveryType }}</td>
											<!--
											<td>{{ orderItem.deliveryPayType }}</td>
											<td>{{ onNumberFormat(orderItem.deliveryFee) }}</td>
											-->
										</tr>
									</tbody>
                                </table>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-sm-2 col-form-label">판매자 이름</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.ownerName }}</span>
							</div>
							<label class="col-sm-2 col-form-label">판매자 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.ownerId }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">수정 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.modifyUserId }}</span>
							</div>
							<label class="col-sm-2 col-form-label">수정 일시</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.modifyDatetime) }}</span>
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
        }
    },
    computed: {
    },
    created() {
    },

    methods: {
        onShow(payload) {
            this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
            this.getModalData();
        },

        getModalData() {
			this.$connect(
                `/api/order/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
				//console.log(this.modalData)
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDetail', {
                isUpdate: this.isUpdate
            })
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },
    },
}
</script>