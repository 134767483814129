<!-- 기간 검색 데이트 -->
<template>
  <div>
      <div class="col-auto f-l">
          <div class="input-group">
              <label for="selStartDate" class="sr-only">검색시작일</label>
              <datepicker 
                valueType="format" 
                v-model="startDate" 
                :language="ko" 
                @input-error="onChangeStartDateType" 
                placeholder="시작일을 선택하세요.(입력예시 : 20230101 )"
              ></datepicker>
              <div class="input-group-prepend">
                  <span class="input-group-text input-group-space">~</span>
              </div>
              <label for="selEndDate" class="sr-only">검색종료일</label>
              <datepicker 
                valueType="format" 
                v-model="endDate" 
                :language="ko" 
                @input-error="onChangeEndDateType" 
                placeholder="종료일을 선택하세요.(입력예시 : 20231231 )"
              ></datepicker>
          </div>
          <!-- /input-group -->
      </div>

      <div class="col-auto f-l" v-show="isShowTerm">
          <a href="javascript:void(0)" :class="['btn', activeTerm === 0? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(0, 0)">오늘</a>
          <!--
          <a href="javascript:void(0)" :class="['btn', activeTerm === 1? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(1, -1)">어제</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 2? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(2, -3)">3일</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 3? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(3, -7)">1주일</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 4? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(4, -30)">1개월</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 5? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(5, -90)">3개월</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 6? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(6, -180)">6개월</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 7? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(7, -365)">1년</a>
          -->
          <a href="javascript:void(0)" :class="['btn', activeTerm === 9? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(9, -1)">어제</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 10? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(10, -30)">당월</a>
          <a href="javascript:void(0)" :class="['btn', activeTerm === 11? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(11, -30)">전월</a>
          <!--
          <a href="javascript:void(0)" :class="['btn', activeTerm === 8? 'btn-outline-primary' : 'btn-secondary']" @click="onClickTerm(8, -3650)">전체</a>
          -->
      </div>
  </div>
</template>

<script>
import * as DateUtils from '../utils/date-utils'
import Datepicker from 'vue2-datepicker'
import { ko } from 'vuejs-datepicker/dist/locale'
import "vue2-datepicker/scss/index.scss";

export default {
    name: 'term-date-picker-string',
    components: {
        Datepicker
    },
    props: {
        isShowTerm: {
            type: Boolean,
            required: true,
            default: true
        }, // 텀 버튼 유무
        defaultTermIndex: {
            type: Number,
            required: true,
            default: -1
        }, // 기본 텀 인덱스
    },
    data() {
        return {
            ko: ko,
            dateFormat: 'yyyy-MM-dd', // 데이터 피커 포멧
            startDate: DateUtils.convertSEDateToStr(new Date()), // 검색 시작 날짜
            endDate  : DateUtils.convertSEDateToStr(new Date()), // 검색 종료 날짜
            activeTerm: this.defaultTermIndex, // 기간 설정 누른 버튼 인덱스 값
            terms: [0, -1, -3, -7, -30, -90, -180, -365, -3650], // 기간 텀
        }
    },
    created() {
        if (this.defaultTermIndex === -1) {
            this.startDate = DateUtils.beginningDate()
            this.endDate = DateUtils.nowDate()
        }else if (this.defaultTermIndex === -111) {
            this.onClickTerm(10, -30)
        }else if (this.defaultTermIndex === -222) {
            this.onClickTerm(11, -30)
        } else {
            this.startDate = DateUtils.addDate(this.endDate, this.terms[this.activeTerm])
        }
    },
    mounted() {
        this.$emit('onChangeDate', {
            startDate: this.startDate,
            endDate: this.endDate
        })
    },
    watch: {
        startDate: {
            handler() {
                this.$emit('onChangeDate', {
                    startDate: this.startDate,
                    endDate: this.endDate
                })
            }
        },
        endDate: {
            handler() {
                this.$emit('onChangeDate', {
                    startDate: this.startDate,
                    endDate: this.endDate
                })
            }
        }
    },
    methods: {
        /**
         * 기간 버튼 클릭 함수
         *
         * @method onClickTerm
         * @param {Number} clickTermIndex 클릭한 기간 버튼 인덱스
         * @param {Number} termDiffDay 차이나는 기간 일
         */
        onClickTerm(clickTermIndex, termDiffDay) {
            this.activeTerm = clickTermIndex

            if(clickTermIndex === 10 || clickTermIndex === 11){
                //당월, 전월
                const now   = new Date();
                const year  = now.getFullYear();
                let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed

                if (clickTermIndex === 11) {
                    month = String(now.getMonth()).padStart(2, '0'); // Months are zero-indexed
                }

                const lastDayOfMonth = new Date(year, parseInt(month), 0).getDate();

                this.startDate = `${year}-${month}-01`;
                this.endDate = `${year}-${month}-${String(lastDayOfMonth).padStart(2, '0')}`;
            }else if(clickTermIndex === 9){
                //어제
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);

                const year = yesterday.getFullYear();
                const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const day = String(yesterday.getDate()).padStart(2, '0');
                
                this.startDate = `${year}-${month}-${day}`;
                this.endDate = `${year}-${month}-${day}`;
            }else{
                this.activeTerm = clickTermIndex
                this.endDate = this.endDate ? this.endDate : DateUtils.nowDate() //빈값 먼저 세팅함

                if (termDiffDay === 0) {
                    // 오늘
                    this.startDate = DateUtils.nowDate()
                    this.endDate = DateUtils.nowDate()
                } else if (termDiffDay === 1) {
                    //전체조회 시(RVI시작날짜)
                    this.startDate = "2024-01-01"
                    this.endDate = DateUtils.nowDate()
                } else if (termDiffDay === -3650) {
                    //전체조회 시(RVI시작날짜)
                    this.startDate = "2024-01-01"
                    this.endDate = DateUtils.nowDate()
                } else {
                    this.startDate = DateUtils.addDate(this.endDate, termDiffDay)
                }
            }
        },
        /**
         * 시작 날짜 변경 시 실행되는 함수
         *
         * @method onChangeStartDateType
         * @param {string} value 변경된 날짜 값
         */
        onChangeStartDateType(value) {
            const isValidDate = /^(\d{4})(\d{2})(\d{2})$/.test(value);
            if (isValidDate) {
                this.startDate = value.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
            } else {
                this.startDate = ''
            }
        },

        /**
         * 종료 날짜 변경 시 실행되는 함수
         *
         * @method onChangeEndDateType
         * @param {string} value 변경된 날짜 값
         */
        onChangeEndDateType(value) {
            const isValidDate = /^(\d{4})(\d{2})(\d{2})$/.test(value);
            if (isValidDate) {
                this.endDate = value.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
            } else {
                this.endDate = ''
            }
        }
    }
}
</script>

<style scope>
.f-l {
    float: left;
}

.col-auto.f-l>a {
    margin-right: 5px;
}

.vdp-datepicker>div>input {
    background: #fff url(../assets/img/svg/input_cal.svg) no-repeat right 5px center;
    padding-right: 30px;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    line-height: 13px;
    height: 35px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdde2;
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    width: 130px !important;
}
</style>
