//공통(최상위)
import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import _ from 'lodash' // lodash 추가
Vue.use(Router)

//components
import Login from './views/Login'
import Main from './views/Main'
import Dashboard from './views/Dashboard'

import OrderList from './views/Order/OrderList'
import ProductList from './views/Product/ProductList'
import SalesList from './views/Sales/SalesList'

import DeliveryTypeSettingList from './views/Setting/DeliveryTypeSettingList'
import Mypage from './views/Setting/Mypage'

import MessageList from './views/Setting/MessageList'
import SendMessageList from './views/Setting/SendMessageList'

import AccountBankHelper from './views/Help/AccountBankHelper'

import NaverBandSetting from './views/NaverBand/NaverBandSetting'
import NaverBandSales from './views/NaverBand/NaverBandSales'


const router = new Router({
    routes: [{
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/main',
            name: 'name',
            component: Main,
            //meta: { requiresAuth: true },
            children: [
                // 대시보드
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard
                },
                {
                    path: 'orderList',
                    name: 'orderList',
                    component: OrderList
                },
                {
                    path: 'productList',
                    name: 'productList',
                    component: ProductList
                },
                {
                    path: 'salesList',
                    name: 'salesList',
                    component: SalesList
                },

                {
                    path: 'deliveryTypeSettingList',
                    name: 'deliveryTypeSettingList',
                    component: DeliveryTypeSettingList
                },
                {
                    path: 'messageList',
                    name: 'messageList',
                    component: MessageList
                },
                {
                    path: 'sendMessageList',
                    name: 'sendMessageList',
                    component: SendMessageList
                },
                /*
                {
                  path: 'mypage',
                  name: 'mypage',
                  component: Mypage
                },
                */
                {
                    path: 'naverBandSetting',
                    name: 'naverBandSetting',
                    component: NaverBandSetting
                },
                {
                    path: 'naverBandSales',
                    name: 'naverBandSales',
                    component: NaverBandSales
                },
            ]
        },
        {
            path: '/accountBankHelper',
            name: 'accountBankHelper',
            component: AccountBankHelper
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/naverBand')) {
		console.log("naverBand")
        next();
    } else {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (_.isEmpty(userInfo)) {
                next({
                    path: '/',
                    //query: { redirect: to.fullPath }
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

/*
router.beforeEach((to, from, next) => {
  const userInfo = store.getters.getUserInfo()
  if (to.matched.some(record => record.meta.requiresAuth)) {  
    if(_.isEmpty(userInfo)){
      next({
        path: '/',
        //query: { redirect: to.fullPath }
      })
    }else{
      next()
    }
  } else {
      next()
  }
})
*/

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta && to.meta.title ? to.meta.title : "골드오더";
    });
});

export default router;