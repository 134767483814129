<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '배송 방법 등록' : '배송 방법 수정'" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">배송 방법명</label>
							<div class="col-sm-10">
								<input type="text" class="form-control" placeholder="배송 방법명을 입력하세요." v-model="modalData.type"/>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">순서</label>
							<div class="col-sm-10">
								<input type="number" class="form-control" placeholder="순서를 입력하세요." v-model="modalData.ordering" />
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
			mode      : 's',
			userInfo  : {}
        }
    },
	created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    methods: {
        onShow(payload) {
			if(payload && payload.seq){
				this.modalData = JSON.parse(JSON.stringify(payload))
				this.getModalData();
				this.mode = 'e'
			}else{
				this.modalData = []
			}
            this.$refs.modal.onShow()
        },

        getModalData() {
			this.$connect(
                `/api/deliveryType/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
            })
		},

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDeliveryTypeSave', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {
			if(!this.modalData.type){
				alert("배송 방법명을 입력하세요.")
				return false
			}
			if(!this.modalData.ordering){
				alert("순서를 입력하세요.")
				return false
			}
			
			let param = this.modalData
			if(this.mode === 's'){
				param = {
					"type"        : this.modalData.type,
					'ordering'    : this.modalData.ordering,
					"createUserId": this.userInfo.id,
				}
			}else{
				param.modifyUserId = this.userInfo.id
			}

			this.$connect(
                `/api/deliveryType`,
                'POST', param
            )
            .then(bodyData => {
				//console.log("bodyData:", bodyData)
				alert("배송방법이 " + (this.mode === 's'?"등록":"수정") + " 되었습니다.")
				this.isUpdate = true
				this.onClickClose()
            })
			
		},

		/**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },
    },
}
</script>