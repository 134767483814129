<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="'바로빌 계좌 연동 해지'" :width="1000">
            <template v-slot:body>
                <div class="row pdd-top-15 mrg-btm-15">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">은행</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.bankName }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">은행코드</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.bankCode }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌번호</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.account }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">예금주</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.accountName }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌 구분</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.accountType === 'C'?'법인':'개인' }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">{{ accountData.accountType === 'C'?'사업자등록번호':'생년월일(6자리)' }}</label>
                            <div class="col-sm-4">
                                <input type="number" class="form-control" :placeholder="accountData.accountType === 'C'?'사업자등록번호':'생년월일(6자리)'" v-model="identityNum" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌 비밀번호</label>
                            <div class="col-sm-4">
                                <form autocomplete="off" style="width:100%;">
                                <input type="text" name="username" autocomplete="off" style="display:none;">
                                <input type="password" class="form-control" placeholder="계좌 비밀번호를 입력하세요." v-model="accountPassword" autocomplete="new-password" />
                                </form>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">은행 빠른조회 아이디</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="은행 빠른조회 아이디를 입력하세요." v-model="webId" autocomplete="off" />
                            </div>
                            <label class="col-sm-2 col-form-label">은행 빠른조회 비밀번호</label>
                            <div class="col-sm-4">
                                <form autocomplete="off" style="width:100%;">
                                <input type="text" name="username" autocomplete="off" style="display:none;">
                                <input type="password" class="form-control" placeholder="은행 빠른조회 비밀번호를 입력하세요." v-model="webPassword" autocomplete="new-password" />
                                </form>
                            </div>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">해지</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal,
    },
    data() {
        return {
            mode                 : 's',
            userData             : {},
            accountData          : {},
            isUpdate             : false,
            webId                : '',
            webPassword          : '',
            accountPassword      : '',
            additionalServiceSeq : 0,
            identityNum          : '',
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log("this.userInfo:", this.userInfo)
        this.serviceTypeList = this.$store.getters.getServiceType
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData             = {}
            this.accountData          = {}
            this.webId                = ''
            this.webPassword          = ''
            this.accountPassword      = ''
            this.additionalServiceSeq = 0
            this.identityNum          = ''

			if(payload){
                //this.mode = 'e'
                this.accountData          = payload.item
                this.userData             = payload.userData
                this.additionalServiceSeq = parseInt(payload.additionalServiceSeq)
			}

            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseBarobillRegistAccount', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {
            if(
                !this.identityNum ||
                !this.accountPassword
            ){
                alert("해지에 필요한 정보(사업자등록번호 또는 생년월일), 계좌비밀번호를 모두 입력해주세요.")
                return false
            }

            const param = {
                "additionalServiceSeq" : this.additionalServiceSeq,
                "account"              : this.accountData.account,
                "accountType"          : this.accountData.accountType,    //'법인C',개인P'
                "accountBankCode"      : this.accountData.bankCode,
                "accountPassword"      : this.accountPassword,
                "webId"                : this.webId,
                "webPassword"          : this.webPassword,
                "identityNum"          : this.identityNum
            }

            this.$connect(
                `/api/member/barobillTerminateAccount/${this.userData.id}`, 
                'POST', param
            )
            .then(bodyData => {
                if(bodyData && bodyData.list){
                    alert("바로빌 입금 확인 서비스에 해지되었습니다.")
                    this.isUpdate = true
                    this.onClickClose()
                }else{
                    alert("바로빌 입금 확인 서비스에 해지에 실패했습니다.\r정보를 정확히 입력해주세요.")
                    this.isUpdate = false
                    this.onClickClose()
                }
            }).catch(error => {
                console.log("error", error);
            })

		},

    },
}
</script>
<style scoped>
.service-select option:disabled {
    text-decoration: line-through;
    color: #aaa;
    background-color: #f5f5f5;
}
</style>