const menus = [
  {
    seq: 0,
    pageTitle: '대시보드',
    routePath: '/main/dashboard',
    isReady: false, // 준비 중 얼럿을 띄울지 유무(true 띄움, false 띄우지 않음)
    menus: []
  }, // 대쉬보드
  {
    seq: 1,
    pageTitle: '대시보드',
    routePath: '/main/dashboard',
    isReady: false,
    menus: []
  }, // 공지사항
  {
    seq: 2,
    title: '주문관리',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '주문관리',
        menu: {
          title: '주문관리',
          menus: [
            {
              seq: 0,
              pageTitle: '주문목록',
              routePath: '/main/orderList'
            },
          ]
        }
      },
    ]
  },
  {
    seq: 3,
    title: '상품관리',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '상품관리',
        menu: {
          title: '상품관리',
          menus: [
            {
              seq: 0,
              pageTitle: '상품목록',
              routePath: '/main/productList'
            },
          ]
        }
      },
    ]
  },
  {
    seq: 4,
    title: '판매관리',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '판매관리',
        menu: {
          title: '판매관리',
          menus: [
            {
              seq: 0,
              pageTitle: '판매목록',
              routePath: '/main/salesList'
            },
          ]
        }
      },
    ]
  },
  {
    seq: 5,
    title: '설정',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '설정',
        menu: {
          title: '설정',
          menus: [
            {
              seq: 0,
              pageTitle: '배송 설정',
              routePath: '/main/deliveryTypeSettingList'
            },
            /*
            {
              seq: 1,
              pageTitle: '내 정보',
              routePath: '/main/mypage'
            }
            */
          ]
        }
      },
      {
        seq: 1,
        icon: 'topnav_icon_cs',
        title: '문자',
        menu: {
          title: '문자',
          menus: [
            {
              seq: 0,
              pageTitle: '문자내용관리',
              routePath: '/main/messageList'
            },
            {
              seq: 1,
              pageTitle: '문자전송내역',
              routePath: '/main/sendMessageList'
            },
          ]
        }
      },
    ]
  },
  {
    seq: 6,
    title: '밴드관리',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '네이버밴드관리',
        menu: {
          title: '네이버밴드관리',
          menus: [
            {
              seq: 0,
              pageTitle: '네이버밴드 설정',
              routePath: '/main/naverBandSetting'
            },
            {
              seq: 1,
              pageTitle: '밴드게시물 판매연동',
              routePath: '/main/naverBandSales'
            },
          ]
        }
      },
    ]
  }

]

export { menus }