<template>
	<div class="layout-main">
		<!-- Header Navbar-->
		<div class="gnbwrap">
			<div class="gnb">
				<ul class="gnb_logo">
					<li>
						<a href="javascript:void(0)" @click="onClickHome">
							<!--<img src="../assets/img/logo-w.png" alt="KT 어드민랩 RVI" />-->
							<span style="color: #FFF; font-size: 20px; font-weight: 600;">골드오더 관리자</span>
						</a>
					</li>
				</ul>
				<ul class="right">
					<li>
						<b style="color: #FFF;"><a href="javascript:void(0)" @click="onClickModalContentMypage">{{ userInfo.username }} 님</a></b>
					</li>
					<li>
						<b style="color: #FFF;"><a href="javascript:void(0)" @click="onClickModalContentMyinfo">내 이용정보</a></b>
					</li>
					<li>
						<a href="javascript:void(0)" @click="onClickLogout">
							<img src="../assets/img/gnb_icon_logout.png" alt="로그아웃" />
						</a>
					</li>
				</ul>
			</div>
		</div>
		<!-- /.gnbwrap -->

		<div class="topnavwrap">
			<ul class="topnav">
				<!--
				<li :class="[rootDepth === 1? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(1)">
					<figure class="admin"></figure> 대시보드
					</a>
				</li>
				-->
				<li :class="[rootDepth === 2? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(2)">
					<figure class="admin"></figure> 주문관리
					</a>
				</li>
				<li :class="[rootDepth === 3? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(3)">
					<figure class="admin"></figure> 상품관리
					</a>
				</li>
				<li :class="[rootDepth === 4? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(4)">
					<figure class="admin"></figure> 판매관리
					</a>
				</li>
				<li :class="[rootDepth === 5? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(5)">
					<figure class="admin"></figure> 설정
					</a>
				</li>

				<template v-if="userInfo.id==='sinheung' || userInfo.id==='tests' || userInfo.id==='y024022'">
				<li :class="[rootDepth === 6? 'active' : '']">
					<a href="javascript:void(0)" @click="onClickMenu(6)">
					<figure class="admin"></figure> 네이버밴드관리
					</a>
				</li>
				</template>

			</ul>
		</div>

		<div class="fluidwrap">

			<!-- Navigation Side Menu -->
			<div 
				:class="['sidenavwrap', isActiveSideNavSub? 'active-sidenavwrap-open' : 'active-sidenavwrap-close']"
				id="sidenav" v-if="navSides[rootDepth].menus.length !== 0">
				<ul class="sidenav">
					<template v-for="item in navSides[rootDepth].menus">
						<li
							:key="item.seq"
							:class="[depth01 === item.seq? 'active' : '']"
							v-if="!(item.title === '문자') || (item.title === '문자' && userInfo.lmsUseYN === 1)"
						>
							<a href="javascript:void(0)" @click="onClickMenu(rootDepth, item.seq, 0)">
							<figure>
								<img :src="`static/img/svg/${item.icon}.svg`" :alt="item.title" />
							</figure>
							<span>{{ item.title }}</span>
							</a>
						</li>
					</template>
				</ul>
			</div>
			<!-- /.sidenavwrap -->
			<div
				:class="['subwrap', isActiveSideNavSub? 'active-sidenav-open' : 'active-sidenav-close']"
				v-if="navSides[rootDepth].menus.length !== 0"
			>
				<ul class="sidenav-sub">
					<li class="title">{{ depth02Title }}</li>
					<li
						v-for="item in depth02Menus"
						:key="item.seq"
						:class="[depth02 === item.seq && isActiveDepth02? 'active' : '']"
					>
						<a href="javascript:void(0)" @click="onClickMenu(rootDepth, depth01, item.seq)">
						<span v-html="item.pageTitle"></span>
						</a>
					</li>
				</ul>
			</div>
			<!--/ Navigation Side Menu -->

			<!-- Content -->
			<div
				:class="['contentwrap', this.navSides[this.rootDepth].menus.length === 0? 'full' : '' , isActiveSideNavSub? 'active-content-open' : 'active-content-close']"
				ref="contentwrap"
			>
				<div class="content">
					<a
						href="javascript:void(0)"
						:class="['sidenav-close', isActiveSideNavSub? '' : 'fa-chevron-right activeT']"
						@click="onClickToggleSideNav"
						v-show="navSides[rootDepth].menus.length !== 0"
					></a>

					<!-- Page-header -->
					<div class="page-header">
						<ul class="breadcrumb float-right">
						<li>
							<a href="javascript:void(0)" @click="onClickHome">홈</a>
						</li>
						<li
							v-for="(item, index) in pageHeaders"
							:key="index"
							:class="[item.isActive? 'active': '']"
						>
							<a href="javascript:void(0)">{{ item.title.replace(/(<([^>]+)>)/gi, ' ') }}</a>
						</li>
						</ul>
						<h1>{{ pageTitle.replace(/(<([^>]+)>)/gi, ' ') }}</h1>
					</div>
					<!--/ Page-header -->

					<!-- Content -->
					<router-view />
					<!--/ Content -->
				</div>
				<div class="footerwrap">
					<p>Copyright © 골드오더. All Rights Reserved.</p>
				</div>
			</div>
		</div>

		<modal-content-mypage 
            ref="modalContentMypage" 
		></modal-content-mypage>

		<modal-content-myinfo 
            ref="modalContentMyinfo" 
		></modal-content-myinfo>

	</div>
  </template>

<script>
import { menus } from '../menu/menu'
import EventBus from '../event-bus'
import MContentMypage from '../modal/Setting/MContentMypage'
import MContentMyinfo from '../modal/Setting/MContentMyinfo'

export default {
	name: 'PosMain',
	components: {
		'modal-content-mypage' : MContentMypage,
		'modal-content-myinfo' : MContentMyinfo
	},
	data() {
		return {
			userInfo: {}, // 사용자 정보
			printer: '', // 프린터 정보
			rootDepth: 0, // 최상위 메뉴 뎁스
			depth01: 0, // 메뉴 뎁스 01
			depth02: 0, // 메뉴 뎁스 02
			depth02Title: '', // 메뉴 뎁스 2 타이틀
			navSides: [], // 메뉴 목록
			pageTitle: '', // 페이지 제목
			depth02Menus: [], // 뎁스 2 메뉴
			isActiveDepth02: true, // 뎁스2 클릭 액티브 활성화 유무
			pageHeaders: [], // 페이지 헤더 목록
			isActiveSideNavSub: true, // 메뉴 뎁스 2 활성화 유무
			notifications : 0,
			todayQnaCnt : 0,
			todayIspCnt : 0,
			qnaStatus : 0,
			ispStatus : 0,
			checkSystemMenu : true,
			checkUsersMenu : true,
			checkPrivacyMenu : true
		}
	},
	computed: {
	},
	destoryDestory() {
		EventBus.$off('onClickMenu')
		EventBus.$off('onSetPageInfo')
	},
	watch: {
		rootDepth: {
			handler() {
				this.setDepth02Title()
				this.setDepth02Menus()
			}
		},
		depth01: {
			handler() {
				this.setDepth02Title()
				this.setDepth02Menus()        
			}
		},
		isActiveSideNavSub: {
			handler() {
				this.$nextTick(() => {
					//console.log('사이드메뉴 이동버튼')
				})
			}
		}
	},
	created() {
		this.userInfo = this.$store.getters.getUserInfo()
		this.navSides = menus
		this.checkSystemMenu = false
		this.checkUsersMenu = true

		this.rootDepth = this.$store.state.menus.rootDepth || 2
		this.depth01 = this.$store.state.menus.depth01 || 0
		this.depth02 = this.$store.state.menus.depth02 || 0
		
		this.onClickMenu(this.rootDepth, this.depth01, this.depth02)
	},
	mounted() {
		this.setPageTitle()
		this.setPageHeader()

		// 메뉴 변경 이벤트 버스
		EventBus.$on('onClickMenu', payload => {
			this.onClickMenu(payload.rootDepth, payload.depth01, payload.depth02)
		})

		// 페이지 타이틀 및 페이지 헤더를 변경하는 이벤트 버스
		EventBus.$on('onSetPageInfo', payload => {
			this.setPageTitle(payload.title)
			this.setPageHeader(payload.pageHeaders)
		})
		
	},
	methods: {

		/**
		 * 홈 클릭 함수
		 * 
		 * @method onClickHome
		 */
		onClickHome() {
			this.rootDepth = 0
			this.depth01 = 0
			this.depth02 = 0
			this.setPageTitle()
			this.setPageHeader()
			//this.$router.replace({ path: '/main/rvi-ew' })
			this.onClickMenu(2, 0, 0)
		},
		/**
		 * 로그아웃 클릭 함수
		 * 
		 * @method onClickLogout
		 */
		onClickLogout() {
			window.sessionStorage.removeItem('userInfo')
			this.$store.dispatch('cleanMenus');
			this.$router.replace({ path: '/' })
		},
		/**
		 * 메뉴 클릭 함수
		 * 
		 * @method onClickMenu
		 * @param {Number} rootDepth 루트 뎁스 메뉴
		 * @param {Number} defaultDepth01 이동할 1번째 뎁스 메뉴
		 * @param {Number} defaultDepth02 이동할 2번째 뎁스 메뉴
		 */
		onClickMenu(rootDepth, defaultDepth01 = 0, defaultDepth02 = 0) {

			//if (this.rootDepth === rootDepth && this.depth01 === defaultDepth01 && this.depth02 === defaultDepth02) return

			/*
			console.log('Root 뎁스: ', rootDepth
			, ', 보일지 유무: ', this.navSides[rootDepth].isView
			, '얼럿 띄울지 유무: ', this.navSides[rootDepth].isReady)
			*/

			if (this.navSides[rootDepth].isReady) {
				alert('준비 중 입니다.')
				return
			}

			this.isActiveDepth02 = true
			this.rootDepth = rootDepth
			this.depth01 = defaultDepth01
			this.depth02 = defaultDepth02

			//console.log('메뉴 클릭(대,중,소): ', this.rootDepth, this.depth01, this.depth02)
			this.setPageTitle()
			this.moveRoutePath()
			this.setPageHeader()

			//
			this.$store.dispatch('setMenus', { rootDepth: this.rootDepth, depth01: this.depth01, depth02: this.depth02 });
		},
		/**
		 * 사이드 서브 네비게이션 활성화 클릭 함수
		 * 
		 * @method onClickToggleSideNav
		 */
		onClickToggleSideNav() {
			this.isActiveSideNavSub = !this.isActiveSideNavSub
		},
		/**
		 * 뎁스에 따라 페이지를 이동하는 함수
		 * 
		 * @method moveRoutePath
		 * @param {String} moveRoutePath 이동할 라우터 경로
		 */
		moveRoutePath(moveRoutePath = '') {
			if (moveRoutePath.length !== 0) {
				//console.log('파라미터 라우터 경로 있음: ', moveRoutePath)
				this.$router.replace({ path: moveRoutePath })
				return
			}

			let routePath = this.navSides[this.rootDepth].routePath

			if (routePath) {
				// 이동 할 경로가 있다면 이동
				//console.log('해당 경로로 이동: ', routePath)
				this.$router.replace({ path: routePath })
				return
			}

			routePath = this.navSides[this.rootDepth].menus[this.depth01].menu.menus[this.depth02].routePath
			//console.log('없으니 여기로 이동: ', routePath)
			this.$router.replace({ path: routePath })
		},
		/**
		 * 페이지 타이틀 셋팅하는 함수
		 * 
		 * @method setPageTitle
		 * @param {String} paramPageTitle 페이지 제목
		 */
		setPageTitle(paramPageTitle = '') {
			if (paramPageTitle.length !== 0) {
				this.pageTitle = paramPageTitle
				return
			}

			let pageTitle = this.navSides[this.rootDepth].pageTitle
			let getPageTitle = pageTitle => pageTitle !== undefined ?
			pageTitle : this.navSides[this.rootDepth].menus[this.depth01].menu.menus[this.depth02].pageTitle
			this.pageTitle = getPageTitle(pageTitle)
		},
		/**
		 * 뎁스 2 타이틀 셋팅하는 함수
		 * 
		 * @method setDepth02Title
		 */
		setDepth02Title() {
			if (this.navSides[this.rootDepth].menus.length === 0) {
				return
			}

			let title = this.navSides[this.rootDepth].menus[this.depth01].menu.title
			let getTitle = title => title ? title : ''
			this.depth02Title = getTitle(title)
		},
		/**
		 * 뎁스 2 선택을 활성화 할지 유무를 셋팅하는 함수
		 * 
		 * @method setActiveDepth02
		 * @param {Boolean} isActive 활성화 유무
		 */
		setActiveDepth02(isActive) {
			this.isActiveDepth02 = isActive
		},
		/**
		 * 뎁스 2 메뉴를 셋팅하는 함수
		 */
		setDepth02Menus() {
			this.depth02Menus = []

			if (this.navSides[this.rootDepth].menus.length === 0) {
				return
			}

			let menus = this.navSides[this.rootDepth].menus[this.depth01].menu.menus
			this.depth02Menus = menus
		},
		/**
		 * 페이지 헤더를 셋팅하는 함수
		 * 
		 * @method setPageHeader
		 * @param {Array} paramPageHeaders 페이지 헤더 목록
		 */
		setPageHeader(paramPageHeaders = []) {
			if (paramPageHeaders.length !== 0) {
				this.pageHeaders = []
				this.pageHeaders = paramPageHeaders
				return
			}

			let results = []

			// 대메뉴 셋팅
			results.push({
				isActive: this.navSides[this.rootDepth].pageTitle ? true : false,
				title: this.navSides[this.rootDepth].pageTitle ?
				this.navSides[this.rootDepth].pageTitle : this.navSides[this.rootDepth].title
			})

			if (this.navSides[this.rootDepth].menus.length !== 0) {
				// 뎁스 1 메뉴 타이틀
				results.push({
					isActive: false,
					title: this.navSides[this.rootDepth].menus[this.depth01].title
				})

				// 뎁스 2 메뉴 타이틀
				results.push({
					isActive: true,
					title: this.navSides[this.rootDepth].menus[this.depth01].menu.menus[this.depth02].pageTitle
				})
			}

			this.pageHeaders = []
			this.pageHeaders = results
		},

		/**
		 * 내 정보 모달 열기
		 * 
		 * @method onClickModalContentMypage
		 */
		onClickModalContentMypage(){
			this.$refs.modalContentMypage.onShow()
		},

		/**
		 * 이용 정보 모달 열기
		 * 
		 * @method onClickModalContentMyinfo
		 */
		 onClickModalContentMyinfo(){
			this.$refs.modalContentMyinfo.onShow()
		}

	}
}
</script>
  
<style scoped>
@import '../assets/css/bootstrap.css';
@import '../assets/css/bootstrap-datepicker.css';
@import '../assets/css/style.css';
@import '../assets/css/custom.css';
@import '../assets/css/font-awesome.min.css';
@import '../assets/css/mobile.css';

.layout-main {
	width: 100%;
	height: 100%;
	font-family: 'Nanum Gothic', 'Malgun Gothic', 'Arial', sans-serif;
	background-color: #edf0f5;
	font-size: 13px;
	padding: 0;
	margin: 0;
	color: #333333;
	min-width: 1180px;
}

.active-sidenav-close {
	visibility: hidden;
	width: 0px;
	-webkit-transition: width 0.25s visibility 0s;
	transition: 0.25s;
	transition-timing-function: ease-in;
}

.active-sidenav-open {
	visibility: unset;
	/*width: 130px;*/
	width: 145px;
	-webkit-transition: width 0.25s visibility 0s;
	transition: 0.25s;
	transition-timing-function: ease-in;
}

/*
.sidenav-sub {
	min-width: 0px;
}
*/

.contentwrap {
	width: 100%;
}

.count-badge {
	position: absolute;
    z-index: 1;
    margin-left: 32px;
    top: 33px;
    display: flex;
    min-width: 110px;
    justify-content: center;
}
.count-badge span {
	text-wrap: nowrap !important;
}
</style>  