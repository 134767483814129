<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '바로빌 계좌 연동 등록' : '바로빌 계좌 연동 수정'" :width="1000">
            <template v-slot:body>
                <div class="row pdd-top-15 mrg-btm-15">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">은행</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.bankName }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">은행코드</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.bankCode }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌번호</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.account }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">예금주</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.accountName }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌 구분</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ accountData.accountType === 'C'?'법인':'개인' }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">{{ accountData.accountType === 'C'?'사업자등록번호':'생년월일(6자리)' }}</label>
                            <div class="col-sm-4">
                                <input type="number" class="form-control" :placeholder="accountData.accountType === 'C'?'사업자등록번호':'생년월일(6자리)'" v-model="identityNum" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌 비밀번호</label>
                            <div class="col-sm-4">
                                <form autocomplete="off" style="width:100%;">
                                <input type="text" name="username" autocomplete="off" style="display:none;">
                                <input type="password" class="form-control" placeholder="계좌 비밀번호를 입력하세요." v-model="accountPassword" autocomplete="new-password" />
                                </form>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">은행 빠른조회 아이디</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="은행 빠른조회 아이디를 입력하세요." v-model="webId" autocomplete="off" />
                            </div>
                            <label class="col-sm-2 col-form-label">은행 빠른조회 비밀번호</label>
                            <div class="col-sm-4">
                                <form autocomplete="off" style="width:100%;">
                                <input type="text" name="username" autocomplete="off" style="display:none;">
                                <input type="password" class="form-control" placeholder="은행 빠른조회 비밀번호를 입력하세요." v-model="webPassword" autocomplete="new-password" />
                                </form>
                            </div>
                        </div>
                        <div class="form-group row">
                            <span class="col-sm-12">※ 계좌 입출금내역을 수집하기 위해서는 각 은행별 빠른조회 서비스에 계좌가 등록되어 있어야 합니다. <a href="/#/accountBankHelper" class="btn btn-secondary" target="_blank">바로보기</a></span>
                        </div>
                        <div class="form-group row">
                            <span class="col-sm-12" style="color: red;">※ 바로빌 입금 확인을 위한 계좌 연동은 계좌당 1만원/1개월의 추가 비용이 발생합니다.</span>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">등록</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal,
    },
    data() {
        return {
            mode                 : 's',
            userData             : {},
            accountData          : {},
            isUpdate             : false,
            webId                : '',
            webPassword          : '',
            accountPassword      : '',
            additionalServiceSeq : 0,
            identityNum          : '',
            bankCodeList         : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        this.serviceTypeList = this.$store.getters.getServiceType
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData             = {}
            this.accountData          = {}
            this.webId                = ''
            this.webPassword          = ''
            this.accountPassword      = ''
            this.additionalServiceSeq = 0
            this.identityNum          = ''
            this.bankCodeList         = []

			if(payload){
                //this.mode = 'e'
                this.accountData          = payload.item
                this.userData             = payload.userData
                this.additionalServiceSeq = parseInt(payload.additionalServiceSeq)
			}
            this.getBankCodeList()
            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseBarobillRegistAccount', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {
            const bankData = this.bankCodeList.find(item => item.code === this.accountData.bankCode)

            if(bankData.isRegistNum === "1"){
                if(!this.identityNum){
                    if(this.accountData.accountType === 'C'){
                        alert("사업자등록번호를 입력해주세요.")
                    }else{
                        alert("생년월일(6자리) 를 입력해주세요.")
                    }
                    return false
                }
            }

            if(bankData.isAccountPassword === "1"){
                if(!this.accountPassword){
                    alert("계좌 비밀번호를 입력해주세요.")
                    return false
                }
            }

            if(bankData.isWbeId === "1"){
                if(!this.webId){
                    alert("은행 빠른 조회 아이디를 입력해주세요.")
                    return false
                }
            }

            if(bankData.isWebPassword === "1"){
                if(!this.accountPassword){
                    alert("은행 빠른 조회 비밀번호를 입력해주세요.")
                    return false
                }
            }

            if(this.additionalServiceSeq < 1){
                alert("부가서비스에 가입되지 않아 연동이 불가능합니다.")
                return false
            }

            const param = {
                "additionalServiceSeq" : this.additionalServiceSeq,
                "account"              : this.accountData.account,
                "accountType"          : this.accountData.accountType,    //'법인C',개인P'
                "accountBankCode"      : this.accountData.bankCode,
                "accountPassword"      : this.accountPassword,
                "webId"                : this.webId,
                "webPassword"          : this.webPassword,
                "identityNum"          : this.identityNum
            }
            
            this.$connect(
                `/api/member/barobillRegistAccount/${this.userData.id}`, 
                'POST', param
            )
            .then(bodyData => {
                console.log(bodyData)
                if(bodyData && bodyData.list){
                    alert("바로빌 입금 확인 서비스에 연동되었습니다.")
                    this.isUpdate = true
                    this.onClickClose()
                }else{
                    alert("바로빌 입금 확인 서비스에 연동에 실패했습니다.\r정보를 정확히 입력해주세요.")
                    this.isUpdate = false
                    this.onClickClose()
                }
            }).catch(error => {
                console.log("error", error);
            })

		},

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
         getBankCodeList(){
            this.$connect(
				`/api/bankcode/listAll`,
                'GET'
            )
            .then(bodyData => {
                this.bankCodeList = bodyData.list
                console.log(this.bankCodeList)
            })
            .catch(error => {
                console.log("error", error);
            })
        },

    },
}
</script>
<style scoped>
.service-select option:disabled {
    text-decoration: line-through;
    color: #aaa;
    background-color: #f5f5f5;
}
</style>