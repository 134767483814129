<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="내 이용정보" :width="1400">
            <template v-slot:body>
                <div>
                    <div class="boxwrap" style="border-color: #999;">
                        <div class="title">
                            <h3 class="text-left">이용정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">이용중인 서비스</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ onGetServiceType(userData.serviceType) }}</span>
                                    </div>
                                    <label class="col-sm-2 col-form-label">서비스 이용일</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ onFormatDateYmd(userData.serviceStartDatetime) }} ~ {{ onFormatDateYmd(userData.serviceEndDatetime) }}</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">바로빌 아이디</label>
                                    <div class="col-sm-10">
                                        <span class="form-control">{{ barobillId }}</span>
                                        <span v-if="barobillId==='미가입'" style="font-size: 12px; color:blue; display: block; margin-top: 10px;">
                                            ※ 바로빌 가입은 관리자에게 문의하시거나 내 정보에서 바로빌 가입 버튼을 클릭해서 가입 가능합니다.
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group row" v-for="(item, index) in userData.memberAdditionalService" :key="index">
                                    <label class="col-sm-2 col-form-label">사용 부가서비스 {{ index+1 }}</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ item.name }}</span>
                                    </div>
                                    <label class="col-sm-2 col-form-label">{{ item.name.includes('문자')?"단가":"가격" }}</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ onNumberFormat(item.unitPrice) }}원</span>
                                    </div>

                                    <template v-if="item.name.includes('입금') || item.name.includes('현금')">
                                        <label class="col-sm-2 col-form-label">&nbsp;</label>
                                        <div class="col-sm-10">
                                            <span v-if="!userData.memberBarobill?.id" style="font-size: 12px; color:blue; display: block; margin-top: 10px;">
                                                ※ 입금 확인 / 현금 영수증 발행 서비스는 바로빌 가입이 필수입니다.
                                            </span>
                                        </div>
                                    </template>
                                </div>
                                <!--
                                <div >
                                    <label class="col-sm-2 col-form-label">문자서비스 사용여부</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ userData.lmsUseYN === 1 ? '사용함':'사용안함' }}</span>
                                    </div>
                                    <label class="col-sm-2 col-form-label">문자서비스 단가</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ userData.lmsUnitPrice }}원 (VAT포함)</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">입금 확인 서비스</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ userData.depositUseYN === 1 ? '사용함':'사용안함' }}</span>
                                    </div>
                                    <label class="col-sm-2 col-form-label">현금 영수증 발행 서비스</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ userData.cashReceiptUseYN === 1 ? '사용함':'사용안함' }}</span>
                                    </div>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template v-slot:footer>
                <!--
                <button type="button" class="btn btn-primary" @click="onClickContentSave">변경 저장</button> 
                -->
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

        <!--확인-->
        <modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
        ></modal-content-save>


    </div>
</template>

<script>
import Modal from '../Modal'

import MContentSave from './MContentSave'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal,
        'modal-content-save'  : MContentSave,
    },
    data() {
        return {
            isView : false,
            userInfo : {},
            userData : {},
            isPasswordChange: false,
            barobillId : ''
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log(this.userInfo)
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow() {
            this.barobillId = ''
            this.getTMData()
            this.$refs.modal.onShow()
        },
        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.userData = {}
            this.$connect(
				`/api/member/${this.userInfo.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                this.userData.password = ''

                if(this.userData.memberAccount.length < 1){
                    this.userData.memberAccount.push({
                        bankName : '',
                        accountName: '',
                        account: ''
                    })
                }

                if(this.userData.memberBarobill.length > 0){
                    this.barobillId = this.userData.memberBarobill[0]?.id?this.userData.memberBarobill[0]?.id:'미가입'
                }
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method onClickContentSave
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        onClickContentSave() {
            this.userData.memberAccount = this.userData.memberAccount.filter(item => item.bankName || item.accountName || item.account);
            this.$refs.modalContentSave.onShow(this.userData)
        },
        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateKor(dateString)
        },

        onClickIsPasswordChange(){
            this.isPasswordChange = !this.isPasswordChange
        },

        async onClickLogoFileUpload(){
            try {
                let uploadFiles = this.$refs.logoFile.files;
                let formData = new FormData();
                formData.append("file", uploadFiles[0]);

                const response = await axios.post(`${this.domainUrl}/api/file/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.userInfo.userToken}`
                    }
                });
                //console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        onClickBankAdd(){
            this.userData.memberAccount.push({
                bankName : '',
                accountName: '',
                account: ''
            })
        },

        onClickBankRemove(index){
            this.userData.memberAccount.splice(index, 1);
        },

        onGetServiceType(number){
            //console.log("onGetServiceType:", number)
            if(number && number > 0){
			    return this.$store.getters.getServiceType(number);
            }
		},

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

    },
}
</script>