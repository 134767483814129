<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="설정 저장" :width="1000">
            <template v-slot:body>
                <div>
                    <table class="table table-bordered">
                        <thead class="thead-contact">
                            <tr>
                                <th scope="col">일련번호</th>
                                <th scope="col">상품명</th>
                                <th scope="col">단가 / 주문단위</th>
                                <th scope="col">판매자 이름</th>
                                <th scope="col">상태</th>
                                <th scope="col">선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, index) in tableList">
                                <tr :key="index">
                                    <td>{{ item.seq }}</td>
                                    <td class="no-pdd" style="text-wrap: nowrap;">
                                        <template v-if="item.productList && item.productList.length > 0">
                                            <table class="table inner-table no-mrg">
                                                <tr v-for="(productItem, productIndex) in item.productList" :key="productIndex">
                                                    <td class="text-left" style="text-wrap: nowrap;">{{ productItem.name }}</td>
                                                </tr>
                                            </table>
                                        </template>
                                    </td>
                                    <td class="no-pdd">
                                        <template v-if="item.productList && item.productList.length > 0">
                                            <table class="table inner-table no-mrg">
                                                <tr v-for="(productItem, productIndex) in item.productList" :key="productIndex">
                                                    <td>{{ onNumberFormat(productItem.unitPrice) }}원</td>
                                                    <td>{{ productItem.unitQuantity?productItem.unitQuantity:1 }}</td>
                                                </tr>
                                            </table>
                                        </template>
                                    </td>
                                    <td>{{ item.ownerUserName }}</td>				
                                    <td>{{ item.status==='ON'?'운영':'중지' }}</td>
                                    <td><button type="button" class="btn btn-primary btn-xs" @click="onClickOK(item)">연동</button></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal
    },
    data() {
        return {
            userInfo  : {},
            bandData  : {},
            isUpdate  : false,
            tableList : [],
            postSeq   : 0
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log("this.userInfo:", this.userInfo)
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow(payload) {
            this.bandData   = JSON.parse(JSON.stringify(payload))

            if(this.bandData.postSeq){
                this.postSeq = this.bandData.postSeq
            }else{
                this.postSeq = 0
            }

            this.getTMData()
            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSelected', {
                isUpdate: this.isUpdate
            })
        },

        async onClickOK(item) {
            try {

                this.$connect(
                    `/api/band/accessInfo/id/${this.userInfo.id}`,
                    'GET'
                )
                .then(bodyData => {
                    const accessInfo = bodyData.list[0]
                    const param = {
                        "accessToken"  : accessInfo.accessToken,
                        "bandKey"      : this.bandData.band_key,
                        "postKey"      : this.bandData.post_key,
                        //"postDate"     : DateUtils.nowDate().substr(0, 10),
                        "postDate"     : this.convertTimestampToDate(this.bandData.created_at),
                        "saleToken"    : item.token,
                        "createUserId" : this.userInfo.id
                    }
                    if(this.postSeq > 0){
                        this.$set(param, 'seq', this.postSeq);
                        this.$set(param, 'modifyUserId', this.userInfo.id);
                    }
                    //console.log(param)

                    this.$connect(
                        `/api/band/post/save`,
                        'POST', param
                    )
                    .then(bodyData => {
                        if(bodyData.list){
                            alert("연동되었습니다.");
                            this.isUpdate = true;
                            this.onClickClose();
                        }else{
                            alert("연동에 실패했습니다.");
                            this.isUpdate = false;
                            this.onClickClose();
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                    })

                })
                .catch(error => {
                    console.log("error", error);
                })

                
            } catch (error) {
                console.error("Error:", error);
                alert("설정을 저장하는 중 오류가 발생했습니다.");
            }
        },

        async getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            const channel = this.$store.getters.getChannel
            const param = {
                "channel"          : channel,
                "startDate"        : "",
                "endDate"          : "",
                "searchType"       : this.searchText?this.searchType:"",
                "searchText"       : this.searchText,
                "orderingParam"    : "",
                "orderingAscending": ""
            }
            //console.log(param)
            try {
                const bodyData = await this.$connect(`/api/sale/list/1/100`, 'POST', param);
                this.tableList = bodyData.list.content;
                //console.log(this.tableList);
                //console.log("bandData:", this.bandData)

                await this.onGetProduct(); // 상품 데이터 로드 후에 테이블을 다시 렌더링
            } catch (error) {
                console.log("error", error);
            }
        },

        async onGetProduct() {
            for (const item of this.tableList) {
                try {
                    const response = await axios.get(`${this.domainUrl}/api/productInSale/get/${item.seq}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.userInfo.userToken}`
                        }
                    });
                    //item.productList = response.data.body.list
                    this.$set(item, 'productList', response.data.body.list)
                    console.log("item:", item.productList)
                } catch (error) {
                    console.error("Error fetching product data:", error)
                }
            }

            // 데이터가 모두 로드된 후에 화면이 다시 렌더링되도록 강제 갱신
            this.$forceUpdate();
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        convertTimestampToDate(timestamp) {
            // Unix 타임스탬프를 밀리초 단위로 변환
            const date = new Date(timestamp);

            // 연, 월, 일을 추출
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
            const day = String(date.getDate()).padStart(2, '0');

            // 형식: YYYY-MM-DD
            return `${year}-${month}-${day}`;
        }
    },
}
</script>
<style scoped>
.inner-table {
    border: none;
    height: 100%;
}
.inner-table td {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}
.inner-table td:first-child {
    border-left: none;
}
.inner-table td:last-child {
    border-right: none;
}
.inner-table tr:nth-child(n+2) td {
    border-top: 1px dashed #dee2e6;
}
</style>