<template>
    <div>
        <div class="boxwrap">
            <div class="title">
                <div class="option-btn mrg-left-10">
                    <a href="javascript:void(0)" class="btn btn-primary btn-sm" @click="onClickModalContentDeliveryTypeSave">배송 방법 등록</a>
                </div>
                <h3 class="text-left">목록 (총
                    <strong class="point">{{ totalCount }}</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col" style="width:120px">일련번호</th>
                        <th scope="col">배송 방법명</th>
                        <th scope="col" style="width:120px">순서</th>
                        <th scope="col" style="width:120px">관리</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index" class="link">
                            <td>{{ item.seq }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.ordering }}</td>
							<td><button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentDeliveryTypeSave(item)">수정</button>&nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentDeliveryTypeDelete(item)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <!--
            <paging-nations 
			ref="pagingNations" 
			:total-content-count="totalCount" 
			:row-per-page=parseInt(tableListRow)
			v-on:onClickPage="getTMPageNum" 
			:key="pagingViewKey" 
			v-show="totalCount !== 0"
			></paging-nations>
            -->

        </div>

		<!--등록/수정-->
		<modal-content-delivery-type-save 
            ref="modalContentDeliveryTypeSave" 
            @onCloseContentDeliveryTypeSave="onCloseContentDeliveryTypeSave"
		></modal-content-delivery-type-save>

        <!--삭제-->
		<modal-content-delivery-type-delete 
            ref="modalContentDeliveryTypeDelete" 
            @onCloseContentDeliveryTypeDelete="onCloseContentDeliveryTypeDelete"
		></modal-content-delivery-type-delete>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

//import Pagingnations from '../../components/Pagingnations'
import MContentDeliveryTypeSave from '../../modal/Setting/MContentDeliveryTypeSave'
import MContentDeliveryTypeDelete from '../../modal/Setting/MContentDeliveryTypeDelete'

export default {
    name: 'deliveryTypeSettingList',
    mixins: [tableMixin],
    components: {
        //'paging-nations'         : Pagingnations,
        'modal-content-delivery-type-save'     : MContentDeliveryTypeSave,
        'modal-content-delivery-type-delete'   : MContentDeliveryTypeDelete,
    },
    data() {
        return {
            totalCount   : 0,
            tableList    : [],
            tableListRow : 10,
            userInfo     : {}
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    mounted() {
        //목록 LOAD
        this.getTMData();
    },

    methods: {
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            this.$connect(
				`/api/deliveryType/list`,
                'POST'
            )
            .then(bodyData => {
                this.tableList = bodyData.list;
                //console.log(this.tableList)
                this.totalCount = this.tableList.length
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 목록 보여지는 ROW 수 변경 함수
         *
         * @method onChangeListRow
         * @param 
         */
        onChangeListRow(){
            this.getTMData()
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
        onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
        onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        /**
         * 등록/수정 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentDeliveryTypeSave(item) {
            this.$refs.modalContentDeliveryTypeSave.onShow(item)
        },

        /**
         * 등록/수정 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentDeliveryTypeSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 삭제 모달 함수
         *
         * @method onClickModalContentDelete
         * @param {Object} item 선택한 아이템 정보
         */
         onClickModalContentDeliveryTypeDelete(item){
            this.$refs.modalContentDeliveryTypeDelete.onShow(item)
        },

        /**
         * 삭제 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentSave
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentDeliveryTypeDelete(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },
    }
}
</script>
<style scoped>
.inner-table {
    border: none;
    height: 100%;
}
.inner-table td {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}
.inner-table td:first-child {
    border-left: none;
}
.inner-table td:last-child {
    border-right: none;
}
.inner-table tr:nth-child(n+2) td {
    border-top: 1px dashed #dee2e6;
}
</style>