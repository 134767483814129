<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="설정 저장">
            <template v-slot:body>
                <div style="color: red; font-size: 18px; font-weight: 600;">
                [주의]<br>
                해당 설정 사항을 저장하시겠습니까?
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal
    },
    data() {
        return {
            userInfo    : {},
            modalData   : {},
            isUpdate    : false,
            formData    : null,
            uploadFiles : null,
            isLogoDel   : false,
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            //console.log("payload:", payload)
            this.modalData   = JSON.parse(JSON.stringify(payload.userData))
            this.formData    = payload.formData
            this.uploadFiles = payload.uploadFiles
            this.isLogoDel   = payload.isLogoDel
            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSave', {
                isUpdate: this.isUpdate
            })
        },

        async onClickOK() {
            try {
                if(this.uploadFiles && this.uploadFiles.length > 0){
                    // 파일 업로드
                    const response = await axios.post(`${this.domainUrl}/api/file/upload`, this.formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.userInfo.userToken}`
                        }
                    });
                    
                    // 업로드한 파일의 URL을 modalData.logo에 설정
                    this.modalData.logo = this.imageUrl + '/upload/' + response.data;
                }
                
                // 필요없는 필드 제거
                delete this.modalData.accountNonExpired;
                delete this.modalData.accountNonLocked;
                delete this.modalData.createDatetime;
                delete this.modalData.modifyDatetime;
                delete this.modalData.userToken;
                delete this.modalData.authorities;
                delete this.modalData.credentialsNonExpired;

                // 비밀번호 설정
                if (!this.modalData.password) {
                    this.modalData.password = this.userInfo.password;
                }

                if(this.isLogoDel){
                    this.modalData.logo = ''
                }

                // API 호출
                const bodyData = await this.$connect(
                    `/api/member`,
                    'POST',
                    this.modalData
                );

                // 성공 시 메시지와 후속 처리
                alert("설정이 저장되었습니다.");
                this.isUpdate = true;
                this.onClickClose();
            } catch (error) {
                console.error("Error:", error);
                alert("설정을 저장하는 중 오류가 발생했습니다.");
            }
        }
    },
}
</script>