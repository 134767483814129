<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="상품 삭제">
            <template v-slot:body>
                <div style="color: red; font-size: 18px; font-weight: 600;">
                [주의]<br>
                해당 상품({{ modalData.name }})이 삭제됩니다.<br>
                판매정보에서도 판매상품에서 삭제됩니다.<br>
                그래도 진행하시겠습니까?
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickOK">삭제</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '../Modal'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData : [],
            isUpdate  : false,
        }
    },
    methods: {
        onShow(payload) {
            this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
            this.getModalData()
        },

        getModalData() {
			this.$connect(
                `/api/product/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDelete', {
                isUpdate: this.isUpdate
            })
        },

        onClickOK() {
            this.$connect(
                `/api/product/${this.modalData.seq}`,
                'DELETE'
            )
            .then(bodyData => {
                alert("상품이 삭제 되었습니다.")
                this.isUpdate = true
                this.onClickClose()
            })
		}
    },
}
</script>