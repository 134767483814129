<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '판매 등록' : '판매 수정'" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">공지사항</label>
							<div class="col-sm-10">
								<textarea style="height: 100px" type="text" class="form-control" placeholder="공지사항을 입력해주세요." 
                                v-model="modalData.notice"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">주문서 URL</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.shortUrl }}</span>
							</div>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">상태</label>
							<div class="col-sm-10">
								<div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        name="saleStatus"
                                        class="custom-control-input"
                                        id="saleStatus1"
                                        value="ON"
                                        v-model="modalData.status"
                                        checked
                                    />
                                    <label class="custom-control-label" for="saleStatus1">운영</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        name="saleStatus"
                                        class="custom-control-input"
                                        id="saleStatus2"
                                        value="OFF"
                                        v-model="modalData.status"
                                    />
                                    <label class="custom-control-label" for="saleStatus2">종료</label>
                                </div>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">상품</label>
							<div class="col-sm-10">
								<table class="table table-bordered text-center">
                                    <colgroup>
                                        <col>
                                        <col width="80">
                                    </colgroup>
									<thead>
										<tr>
											<th>상품명</th>
											<th><button type="button" class="btn btn-secondary btn-xs" @click="onClickProductList">추가</button></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in productList" :key="index">
											<td>
                                                <select class="form-control" v-model="productList[index].productSeq" @change="onChangeProduct(index, $event.target.value)">
                                                <template v-for="(productItem, productIndex) in product">
                                                    <option :key="productIndex" :value="productItem.seq">{{ productItem.name }}</option>
                                                </template>
                                                </select>
                                            </td>
											<td><button type="button" class="btn btn-secondary btn-xs" @click="onDeleteProductList(index)">삭제</button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import sha512 from 'js-sha512';
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData   : [],
            lastData    : [],
            isUpdate    : false,
			mode        : 's',
            product     : [],
            productList : [],
            userInfo    : {},
            channel     : '',
            token       : '',
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log("this.userInfo:", this.userInfo)
        this.channel = this.$store.getters.getChannel
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow(payload) {
            this.getProduct()

			if(payload && payload.seq){
                this.mode = 'e'

				this.modalData = JSON.parse(JSON.stringify(payload))
				this.getModalData();
                this.getProductData();
				
			}else{
                this.modalData   = []
                this.lastData = []
                this.getLastData()
                this.getShortUrl()

                this.modalData.status = 'ON'
			}
            this.$refs.modal.onShow()
        },

        getModalData() {
			this.$connect(
                `/api/sale/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
                this.token = this.modalData.token
            })
        },

        /**
         * 마지막 판매 데이터 가져오기 - 공지사항 내용에 적용
         *
         * @method getLastData
         */
        getLastData(){
            this.$connect(
				`/api/sale/last/${this.channel}`,
                'GET'
            )
            .then(bodyData => {
                this.lastData = bodyData.list
                if(bodyData.list){
                    this.$set(this.modalData, 'notice', this.lastData.notice);
                }
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * URL 만들기
         *
         * @method getShortUrl
         */
        getShortUrl(){
            const now = new Date();
            this.token = sha512(this.userInfo.id + now.getTime()).substring(0, 84);
            let originUrl = 'https://goldcarriage.kr/' + this.channel + '?token=' + this.token;

            //const originUrl = location.protocol + '//goldcarriage.kr/' + this.channel + '?token=' + this.token;
            //console.log("window.location.hostname:", window.location.hostname)
            const hostname = window.location.hostname
            if(hostname === 'localhost' || hostname === '1.241.84.248'){
                originUrl = 'http://1.241.84.248:8000/' + this.channel + '?token=' + this.token;
            }

            this.$connect(
				`/api/shorturl/get`,
                'POST', { "originUrl" : originUrl }
            )
            .then(bodyData => {
                const result = JSON.parse(bodyData.list).result
                this.$set(this.modalData, 'originUrl', result.orgUrl);
                this.$set(this.modalData, 'shortUrl', result.url);
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 해당 상품 목록 불러오기
         *
         * @method getProduct
         */
        async getProduct(){
            const response = await axios.get(`${this.domainUrl}/api/product/listByChannel/${this.userInfo.id}/${this.channel}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.userInfo.userToken}`
                }
            });
            this.product = response.data
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSave', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {
            if(!this.modalData.notice){
                alert("공지사항을 입력해주세요.")
                return false
            }
            if(this.productList < 1){
                alert("판매상품을 선택해주세요.")
                return false
            }

            let param = this.modalData
            param = {
                saleEndDate   : "",
                saleStartDate : "",
                token         : this.token,
                ownerUserId   : this.modalData.ownerUserId?this.modalData.ownerUserId:this.userInfo.id,
                ownerUserName : this.modalData.ownerUserName?this.modalData.ownerUserName:this.userInfo.name,
                role          : this.modalData.role?this.modalData.role:this.userInfo.role,
                businessType  : this.channel,
                status        : this.modalData.status,
                notice        : this.modalData.notice,
                originUrl     : this.modalData.originUrl,
                shortUrl      : this.modalData.shortUrl,
                createUserId  : this.modalData.createUserId?this.modalData.createUserId:this.userInfo.id
            }

            if(this.mode === 'e'){
                param.seq          = this.modalData.seq
                param.modifyUserId = this.userInfo.id
            }

            this.$connect(
                `/api/sale`,
                'POST', param
            )
            .then(bodyData => {
                const saleSeq = bodyData.list.seq
                this.productList.forEach(item => {
                    item.saleSeq = saleSeq
                })
                this.$connect(
                    `/api/productInSale/saveAll/${saleSeq}`,
                    'POST', this.productList
                )
                .then(productBodyData => {
                    this.isUpdate = true
                    this.onClickClose()
                })
            })
		},

        onClickProductList(){
            this.productList.push({
                "saleSeq"   : 0,
                "productSeq": this.product[this.productList.length].seq
            })
        },
        onDeleteProductList(index) {
            this.productList.splice(index, 1);
        },
        onChangeProduct(index, productSeq){
            this.productList[index].productSeq = productSeq
        },

        getProductData(){
            this.$connect(
                `/api/productInSale/get/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.productList = []
                bodyData.list.forEach(item => {
                    this.productList.push({
                        "saleSeq"   : this.modalData.seq,
                        "productSeq": item.seq
                    })
                })
            })
        }
    },
}
</script>